import * as React from "react"

import { Entity, entityNameAndId, entityParamsAsString } from "react-app/Permissions/data/Entity"

interface EntityTdProps {
  entity: Entity | null
}

const EntityTd = ({ entity }: EntityTdProps): JSX.Element => {
  return entity ? (
    <td className="tbody__cell tbody__cell_link">
      <a className="tbody__link" href={`/settings?${entityParamsAsString(entity)}`}>
        {entityNameAndId(entity)}
      </a>
    </td>
  ) : (
    <td className="tbody__cell">-</td>
  )
}

export default EntityTd
