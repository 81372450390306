import { ToolTip } from "@hubrise/react-components"
import type { ToolTipPlacement } from "@hubrise/react-components"
import { DateTime } from "luxon"
import type { WeekdayNumbers } from "luxon"
import * as React from "react"
import { useTranslation } from "react-i18next"

import { bodyData } from "react-app/utils/config"
import i18n from "react-app/utils/i18n"
import { DAYS, Hour, HOURS } from "react-app/utils/queries/OrderData"
import { currencyFormatter, DateRange, isWeekdayInRange, nowInLocalTime } from "react-app/utils/utils"

import { Cell, DayHeader, EmptyCell, EmptyHeader, Grid, HourHeader, ScrollContainer } from "./Styles"
import { useHeatmapOrderData } from "./useHeatmapOrderData"

interface PeakHoursProps {
  dateRange: DateRange
}

const PeakHours = ({ dateRange }: PeakHoursProps): JSX.Element => {
  const { t } = useTranslation()

  const { start, end } = dateRange
  const { data, isLoading, error } = useHeatmapOrderData(start, end)

  if (isLoading) return <div>{t("general.loading")}</div>
  if (error) return <div>{t("general.error", { message: error.message })}</div>

  const maxOrderCount = Math.max(
    ...Array.from(data.values()).flatMap((hourlyData) =>
      Array.from(hourlyData.values()).map(({ orderCount }) => orderCount),
    ),
  )

  const { cutoff_time } = bodyData() // Ex.: "06:00"
  const firstHour = parseInt(cutoff_time.split(":")[0]) as Hour
  const reorderedHours = [...HOURS.slice(firstHour), ...HOURS.slice(0, firstHour)]
  const effectiveEnd = DateTime.min(end.toDateTimeWithCutoff().plus({ day: 1 }).minus({ second: 1 }), nowInLocalTime())

  const toolTipPlacement = (dayIndex: number, hourIndex: number): ToolTipPlacement => {
    if (dayIndex <= 4) {
      return hourIndex <= 16 ? "south_east" : "south_west"
    } else {
      return hourIndex <= 16 ? "north_east" : "north_west"
    }
  }

  return (
    <ScrollContainer>
      <Grid>
        <EmptyHeader />
        {reorderedHours.map((hour) => (
          <HourHeader key={hour}>{hour}h</HourHeader>
        ))}

        {DAYS.map((day, dayIndex) => {
          const disabled = !isWeekdayInRange(
            start.toDateTimeWithCutoff(),
            effectiveEnd,
            (dayIndex + 1) as WeekdayNumbers,
          )

          return (
            <React.Fragment key={day}>
              <DayHeader $disabled={disabled} data-testid={`day-header-${dayIndex}`}>
                {localiseDay(dayIndex)}
              </DayHeader>

              {reorderedHours.map((hour, hourIndex) => {
                const { orderCount, totalAmount } = data.get(day)?.get(hour) ?? { orderCount: 0, totalAmount: 0 }

                const commonCellProps = {
                  $midnight: hour === 0,
                  $firstRow: dayIndex === 0,
                  "data-testid": `cell-${dayIndex}-${hourIndex}`,
                }

                if (orderCount > 0) {
                  const intensity = orderCount / maxOrderCount
                  const tooltipLabel = `${t("general.orders", { count: orderCount })} (${currencyFormatter({
                    digits: true,
                  }).format(totalAmount)})`
                  return (
                    <ToolTip key={hour} label={tooltipLabel} toolTipPlacement={toolTipPlacement(dayIndex, hourIndex)}>
                      <Cell $intensity={intensity} {...commonCellProps}>
                        {orderCount}
                      </Cell>
                    </ToolTip>
                  )
                } else {
                  return <EmptyCell key={hour} $disabled={disabled} {...commonCellProps} />
                }
              })}
            </React.Fragment>
          )
        })}
      </Grid>
    </ScrollContainer>
  )
}

const localiseDay = (dayIndex: number): string => {
  return DateTime.fromObject({ weekday: (dayIndex + 1) as WeekdayNumbers })
    .setLocale(i18n.language)
    .toFormat("cccc")
    .replace(/^\w/, (c) => c.toUpperCase())
}

export default PeakHours
