import { colors, sizes, themeColors } from "@hubrise/react-components"
import { styled } from "styled-components"

export const ModalContainer = styled.div``

export const ModalTitle = styled.div`
  padding: 0.625rem 1rem;
  color: ${colors.textDark};
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 1.5;
  background-color: ${colors.backgroundLightest};
  border-bottom: 1px solid ${colors.borderMedium};
  border-radius: ${sizes.borderRadius} ${sizes.borderRadius} 0 0;
`

export const ModalTitleDanger = styled(ModalTitle)`
  color: ${themeColors.danger};
`

export const ModalContent = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

export const ModalParagraph = styled.div`
  line-height: 1.4;
`

export const ModalFooterButtons = styled.div`
  padding: 0.625rem 1rem;
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
`
