import { Icon } from "@hubrise/react-components"
import * as React from "react"
import { useTranslation } from "react-i18next"

import { useProductData } from "react-app/utils/queries/ProductData"
import type { DateRange } from "react-app/utils/utils"

import {
  Item,
  Name,
  Quantity,
  NameAndQuantity,
  Count,
  Footer,
  FooterLink,
  MultiColumn,
  StyledTopProducts,
} from "./Styles"

interface TopProductsProps {
  dateRange: DateRange
  viewAll: boolean
  toggleViewAll: () => void
}

const FOLDED_LIMIT = 30

const TopProducts = ({ dateRange, viewAll, toggleViewAll }: TopProductsProps): JSX.Element => {
  const { t } = useTranslation()

  const limit = viewAll ? 100000 : FOLDED_LIMIT + 1
  const { data, isLoading, error } = useProductData(
    dateRange.start.toDateTimeWithCutoff(),
    dateRange.end.toDateTimeWithCutoff().plus({ day: 1 }),
    { limit },
  )

  if (isLoading || !data) return <div>{t("general.loading")}</div>
  if (error) return <div>{t("general.error", { message: error.message })}</div>

  let hasMore = false
  if (!viewAll && data.length > FOLDED_LIMIT) {
    hasMore = true
    data.pop()
  }

  let footer: JSX.Element | null = null
  if (viewAll) {
    footer = (
      <FooterLink onClick={toggleViewAll}>
        <Icon code="keyboard_double_arrow_up" />
        {t("dashboard.top_list.view_less")}
      </FooterLink>
    )
  } else if (hasMore) {
    footer = (
      <FooterLink onClick={toggleViewAll}>
        <Icon code="keyboard_double_arrow_down" />
        {t("dashboard.top_list.view_all")}
      </FooterLink>
    )
  }

  return (
    <StyledTopProducts>
      <MultiColumn>
        {data.map((item, index) => (
          <Item key={index} data-testid={`item-${index}`}>
            <Count>{`${index + 1}.`}</Count>
            <NameAndQuantity>
              <Name>{item.product_name}</Name>
              <Quantity>{item.total_quantity}</Quantity>
            </NameAndQuantity>
          </Item>
        ))}
      </MultiColumn>

      {footer && <Footer>{footer}</Footer>}
    </StyledTopProducts>
  )
}

export default TopProducts
