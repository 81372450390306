const onUpload = (_rootNode: HTMLElement, fileInputNode: HTMLInputElement) => {
  const rootNode = $(_rootNode)
  rootNode.find("[data-uploaded]").show()
  rootNode.find("[data-empty]").hide()
  rootNode.find<HTMLInputElement>("[data-remove]")[0].checked = false

  const previewNode = $(rootNode.find("[data-preview]")[0])
  previewSelectedImage($(fileInputNode)[0], previewNode)
}

const onRemove = (_rootNode: HTMLElement) => {
  const rootNode = $(_rootNode)
  rootNode.find("[data-uploaded]").hide()
  rootNode.find("[data-empty]").show()
  rootNode.find<HTMLInputElement>("[data-remove]")[0].checked = true
}

const previewSelectedImage = (input: HTMLInputElement, previewNode: JQuery<HTMLElement>) => {
  if (input.files && input.files[0] !== undefined) {
    const reader = new FileReader()

    reader.onload = function (e: ProgressEvent<FileReader>) {
      if (e.target && typeof e.target.result === "string") {
        $(previewNode).attr("src", e.target.result)
      }
    }
    reader.readAsDataURL(input.files[0])
  }
}

const ImageInput: typeof window.ImageInput = {
  onUpload,
  onRemove,
}

export default ImageInput
