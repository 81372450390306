import { OrderData } from "react-app/utils/queries/OrderData"

export type OrderMetrics = {
  totalRevenue: number
  orderCount: number
  avgOrderValue: number
}

export const calculateOrderMetrics = (data: OrderData): OrderMetrics => {
  const totalRevenue = data.reduce((acc, item) => acc + parseFloat(item.total_amount), 0)
  const orderCount = data.reduce((acc, item) => acc + item.order_count, 0)

  return {
    totalRevenue,
    orderCount,
    avgOrderValue: orderCount > 0 ? totalRevenue / orderCount : 0,
  }
}

export type Progression = {
  value: number
  isPositive: boolean
}

export const calculateProgression = (current: number, previous: number): Progression => {
  const progression = previous !== 0 ? ((current - previous) / previous) * 100 : 0
  return {
    value: progression,
    isPositive: progression >= 0,
  }
}

export type MetricType = {
  title: string
  key: keyof OrderMetrics
  format: (value: number) => string
}
