import * as React from "react"
import { useTranslation } from "react-i18next"

import SelfLockingWarning from "react-app/Permissions/SelfLockingWarning"
import ManagerModal from "react-app/shared/ManagerModal"

interface SelfLockingWarningModalProps {
  onCancel: () => void
  onConfirm: () => void
}

const SelfLockingWarningModal = ({ onCancel, onConfirm }: SelfLockingWarningModalProps) => {
  const { t } = useTranslation()

  return (
    <ManagerModal onClose={onCancel}>
      <SelfLockingWarning
        message={t("roles.self_locking_warning.remove_message_1")}
        onConfirm={onConfirm}
        onCancel={onCancel}
      />
    </ManagerModal>
  )
}

export default SelfLockingWarningModal
