const loadPermissionsAndButtonsFn = (authorizePath: string, elementName: string, queryParamName: string) => {
  return () => {
    const selectValue = $(`select[name="${elementName}"] > option:selected`).val()
    const inputValue = $(`input[name="${elementName}"]`).val()
    const id = selectValue ?? inputValue

    $.ajax({
      url: authorizePath,
      method: "POST",
      data: [queryParamName, id].join("="),
    }).done(function (formHtml: string) {
      const form = $("#permissions-and-buttons").html(formHtml)
      window.Page.init(form)
    })
  }
}

const OAuth: typeof window.OAuth = {
  loadPermissionsAndButtonsFn,
}

export default OAuth
