import { breakpoints, ToolTip } from "@hubrise/react-components"
import { styled } from "styled-components"

export const DateSelector = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`

export const OverviewGrid = styled.div`
  display: grid;
  gap: 1.2rem;
  grid-template-columns: 1fr;

  @media (min-width: ${breakpoints.tablet}) {
    grid-template-columns: repeat(3, 1fr);
  }
`

export const OverviewCard = styled.div<{ $span: number }>`
  background-color: #fff;
  border-radius: 0.3rem;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.05);
  font-size: 0.875rem;
  grid-column: 1 / -1;

  display: flex;
  flex-direction: column;
  min-width: 0; // Allow grid elements that contain fixed sized canvas to shrink below their content size (default is "auto").

  @media (min-width: ${breakpoints.tablet}) {
    grid-column: ${({ $span }) => `span ${$span}`};
  }
`

export const CardHeader = styled.div`
  padding: 1rem;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
  background-color: #fbfbfb;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  align-items: center;
  font-weight: bold;
`

export const StyledToolTip = styled(ToolTip)`
  min-width: 20rem;
  font-weight: normal;
  white-space: normal;
`

export const CardHeaderIcon = styled.div`
  cursor: pointer;
`

export const CardBody = styled.div`
  flex: 1;
  padding: 1rem;
  background-color: #fff;
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
`
