import {
  ArcElement,
  BarController,
  BarElement,
  Chart as ChartJS,
  CategoryScale,
  DoughnutController,
  Filler,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  Tooltip,
} from "chart.js"

ChartJS.register(
  ArcElement,
  BarController,
  BarElement,
  CategoryScale,
  DoughnutController,
  Filler,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  Tooltip,
)
ChartJS.defaults.font.family = "Ubuntu"
ChartJS.defaults.plugins.legend.display = false
