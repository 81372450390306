import { ToolTip } from "@hubrise/react-components"
import { DateTime } from "luxon"
import * as React from "react"
import { useTranslation } from "react-i18next"

import { bodyData } from "react-app/utils/config"
import { useOrderData } from "react-app/utils/queries/OrderData"
import { currencyFormatter, nowInLocalTime } from "react-app/utils/utils"
import type { DateRange } from "react-app/utils/utils"

import Checkbox from "./Checkbox"
import Compare from "./Compare"
import { calculateOrderMetrics, MetricType } from "./OrderMetrics"
import { CurrentValue, Item, Metrics, PreviousPeriod, Summary, Title, ValueContainer } from "./Styles"

interface SalesSummaryProps {
  dateRange: DateRange
  previousDateRange?: DateRange
  compareWithLabel?: string
  compare: boolean
  toggleCompare: () => void
}

const SalesSummary = ({
  dateRange,
  previousDateRange,
  compareWithLabel,
  compare,
  toggleCompare,
}: SalesSummaryProps): JSX.Element => {
  const { t } = useTranslation()

  const { location_id } = bodyData()
  const isLocation = location_id !== null

  const { start, end } = dateRange
  const { data, isLoading, error } = useOrderData(
    start.toDateTimeWithCutoff(),
    end.toDateTimeWithCutoff().plus({ day: 1 }),
    { groupBy: "date" },
  )

  // We must compare with a period of the same duration
  const previousStart = (previousDateRange ?? dateRange).start.toDateTimeWithCutoff()
  const effectiveEnd = DateTime.min(end.toDateTimeWithCutoff().plus({ day: 1 }), nowInLocalTime())
  const duration = effectiveEnd.diff(start.toDateTimeWithCutoff())
  const previousEnd = previousStart.plus(duration).minus({ milliseconds: 1 })

  const {
    data: previousData,
    isLoading: isLoadingPrevious,
    error: errorPrevious,
  } = useOrderData(
    previousStart,
    previousEnd.endOf("minute"), // Round up to use the query cache effectively
    { groupBy: "date", enabled: !!previousDateRange },
  )

  if (isLoading || isLoadingPrevious || !data || (previousDateRange && !previousData))
    return <div>{t("general.loading")}</div>
  if (error || errorPrevious)
    return <div>{t("general.error", { message: error?.message ?? errorPrevious?.message })}</div>

  const currentMetrics = calculateOrderMetrics(data)

  const metricsTypes: Array<MetricType> = [
    {
      title: t("dashboard.sales_summary.total_revenue"),
      key: "totalRevenue",
      format: (value: number) => currencyFormatter({ digits: true }).format(value),
    },
    {
      title: t("dashboard.sales_summary.total_orders"),
      key: "orderCount",
      format: (value: number) => value.toString(),
    },
    {
      title: t("dashboard.sales_summary.average_order"),
      key: "avgOrderValue",
      format: (value: number) => currencyFormatter({ digits: true }).format(value),
    },
  ]

  return (
    <Summary>
      <Metrics>
        {metricsTypes.map((metricType, index) => {
          const currentValue = currentMetrics[metricType.key]

          return (
            <Item key={index}>
              <Title>{metricType.title + t("general.colon")}</Title>

              <ValueContainer>
                <CurrentValue data-testid={metricType.key}>{metricType.format(currentValue)}</CurrentValue>
                {compare && previousDateRange && previousData && (
                  <Compare {...{ previousData, metricType, previousStart, previousEnd, currentValue }} />
                )}
              </ValueContainer>
            </Item>
          )
        })}
      </Metrics>

      <PreviousPeriod>
        {previousDateRange ? (
          <Checkbox checked={compare} onChange={toggleCompare} label={compareWithLabel!} />
        ) : (
          <ToolTip
            label={
              isLocation
                ? t("dashboard.sales_summary.comparison_unavailable.tooltip.location")
                : t("dashboard.sales_summary.comparison_unavailable.tooltip.account")
            }
          >
            <Checkbox
              checked={compare}
              onChange={() => {}}
              label={t("dashboard.sales_summary.comparison_unavailable.label")}
              disabled={true}
            />
          </ToolTip>
        )}
      </PreviousPeriod>
    </Summary>
  )
}

export default SalesSummary
