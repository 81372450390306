const open = (
  modalSelector: string,
  params: { action?: string; method?: string; title?: string; message?: string } = {},
) => {
  const modal = $(modalSelector)
  if (params.action !== undefined) {
    modal.find("form").attr("action", params.action)
  }

  if (params.method !== undefined) {
    modal.find('form input[name="_method"]').val(params.method)
  }

  if (params.title !== undefined) {
    modal.find(".js-title").text(params.title)
  }

  if (params.message !== undefined) {
    modal.find(".js-message").text(params.message)
  }

  modal.foundation("open")
  modal.find('form input[type="submit"]').focus()
}

const openAjax = (modalSelector: string, url: string) => {
  const modal = $(modalSelector)

  $.ajax(url).done(function (response: string) {
    modal.html(response).foundation("open")
  })
}

const Modal: typeof window.Modal = {
  open,
  openAjax,
}

export default Modal
