import { DateTime } from "luxon"

export const buildUrlWithTimes = (path: string, startTime: DateTime, endTime: DateTime, params: URLSearchParams) => {
  const encodeTime = (time: DateTime) => time.toISO({ suppressMilliseconds: true, includeOffset: false })!

  // Manually construct start_time and end_time to avoid encoding colons and keep URLs readable
  const start_time = `start_time=${encodeTime(startTime)}`
  const end_time = `end_time=${encodeTime(endTime)}`

  return new URL(`${path}?${params.toString()}&${start_time}&${end_time}`, window.location.origin)
}
