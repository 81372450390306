import type { Chart, ChartArea, DatasetController, Element, Plugin, ChartDataset } from "chart.js"
import type BarElement from "chart.js/dist/elements/element.bar"

type BoxBorderDashDataset = ChartDataset<"bar"> & {
  boxBorderWidth?: number
  boxBorderDash?: number[]
}

const getArea = (element: BarElement): Pick<ChartArea, "top" | "left" | "right" | "bottom"> => {
  const half = element.width / 2
  return {
    top: Math.min(element.y, element.base as number),
    left: element.x - half,
    right: element.x + half,
    bottom: Math.max(element.y, element.base as number),
  }
}

const boxBorderDashPlugin: Plugin = {
  id: "boxBorderDash",
  afterDatasetDraw(chart: Chart, args: { meta: { data: Element[]; controller: DatasetController } }): void {
    const { meta } = args

    const dataset = meta.controller.getDataset() as BoxBorderDashDataset
    const { boxBorderWidth, boxBorderDash } = dataset

    if (boxBorderDash && boxBorderDash.length && boxBorderWidth !== undefined) {
      meta.data.forEach((element: Element) => {
        const ctx = chart.ctx

        const { top, left, right, bottom } = getArea(element as BarElement)
        if (top === bottom) return // Skip empty bars

        // Draw a dashed border
        ctx.beginPath()
        ctx.lineWidth = boxBorderWidth
        ctx.strokeStyle = element.options.borderColor as string
        ctx.setLineDash(boxBorderDash)
        ctx.moveTo(left, top)
        ctx.lineTo(right, top)
        ctx.lineTo(right, bottom)
        ctx.lineTo(left, bottom)
        ctx.lineTo(left, top)
        ctx.stroke()
        ctx.save()
      })
    }
  },
}

export default boxBorderDashPlugin
