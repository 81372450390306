let startOffset: number
let offset: number
let callbackOrUrl: string | (() => string)
let limit: number
let isLoading: boolean
let lastLoadPositionY: number

const init = (_limit: number, _callbackOrUrl: string | (() => string), _startOffset = 0): void => {
  limit = _limit
  callbackOrUrl = _callbackOrUrl
  startOffset = _startOffset
  offset = _startOffset
  isLoading = false
  lastLoadPositionY = 0

  refreshAll()

  $(window).scroll(function () {
    if (
      typeof window !== "undefined" &&
      !isLoading &&
      window.scrollY + window.innerHeight >= document.documentElement.scrollHeight - 10 &&
      Math.abs(lastLoadPositionY - window.scrollY) > 1
    ) {
      triggerLoading()
      lastLoadPositionY = window.scrollY
    }
  })
}

const triggerLoading = (): void => {
  // Append to [data-autoscroll-list], or fallback to .page__content
  const list = $("[data-autoscroll-list]").length ? $("[data-autoscroll-list]") : $(".page__content")

  const setLoading = (state: boolean) => {
    isLoading = state
    if (state) {
      list.append('<div data-autoscroll-spinner=""> </div>')
    } else {
      $("[data-autoscroll-spinner]").remove()
    }
  }

  const url = typeof callbackOrUrl === "function" ? callbackOrUrl() : callbackOrUrl
  setLoading(true)

  $.ajax({
    type: "GET",
    url,
    dataType: "text",
    data: {
      offset: offset || $("[data-autoscroll-row]").length,
      limit,
    },
    success: function (response: string) {
      const newContent = $(response).appendTo(list)
      window.Page.init(newContent)
    },
    error: function (jqXHR: JQuery.jqXHR) {
      throw new Error(`AJAX error: ${jqXHR.statusText}`)
    },
    complete: function () {
      setLoading(false)
    },
  })
}

const refreshAll = (): void => {
  setOffset(startOffset)
  $("[data-autoscroll-row]").remove()
  triggerLoading()
}

const setOffset = (_offset: number): void => {
  offset = _offset
}

const Autoscroll: typeof window.Autoscroll = {
  init,
  refreshAll,
  setOffset,
}

export default Autoscroll
