import type { TFunction } from "i18next"
import { DateTime } from "luxon"
import * as React from "react"
import { useTranslation } from "react-i18next"

import PlainDate from "react-app/utils/PlainDate"
import i18n from "react-app/utils/i18n"
import { OrderData, useOrderData } from "react-app/utils/queries/OrderData"
import { cutoffHour } from "react-app/utils/utils"

export type GraphType = "revenue" | "orders" | "average_order"
type Granularity = "hour" | "day" | "week" | "month"

type AggregatedOrderData = {
  values: Array<{
    label: string
    value: number | null
  }>
  granularity: Granularity
}

export type UseAggregatedOrderDataResult = {
  data: AggregatedOrderData
  isLoading: boolean
  error: Error | null
}

interface UseAggregatedDataOptions {
  enabled?: boolean
}

export const useAggregatedOrderData = (
  startDate: PlainDate,
  endDate: PlainDate,
  type: GraphType,
  options?: UseAggregatedDataOptions,
): UseAggregatedOrderDataResult => {
  const { t } = useTranslation()

  const startTime = startDate.toDateTimeWithCutoff()
  const endTime = endDate.toDateTimeWithCutoff().plus({ day: 1 })

  const granularity = computeGranularity(startTime, endTime)
  const includeHours = granularity === "hour"

  const { data, isLoading, error } = useOrderData(startTime, endTime, {
    groupBy: includeHours ? "date-hour" : "date",
    enabled: options?.enabled,
  })

  const processedData: AggregatedOrderData = React.useMemo<AggregatedOrderData>(() => {
    if (isLoading || error || !data) return { values: [], granularity: "day" }

    const values: AggregatedOrderData["values"] = []

    let index = 0
    let startPeriod = startTime
    while (startPeriod < endTime) {
      const startIndex = index
      const endPeriod = startPeriod.plus({ [granularity + "s"]: 1 })
      while (index < data.length && orderDataDateTime(data[index]) < endPeriod) index++
      values.push({
        label: formatLabel(startPeriod, granularity, t),
        value: aggregateValue(data, type, startIndex, index),
      })
      startPeriod = endPeriod
    }

    return { values, granularity } satisfies AggregatedOrderData
  }, [isLoading, error, data, startTime, endTime, granularity, t, type])

  return { data: processedData, isLoading, error }
}

const computeGranularity = (startTime: DateTime, endTime: DateTime): Granularity => {
  const MAX_POINTS = 35 // Keep above 31 to avoid the month view to switch to week axis.

  const diff = endTime.diff(startTime, "hours").hours
  if (diff <= MAX_POINTS) return "hour"
  if (diff <= MAX_POINTS * 24) return "day"
  if (diff <= MAX_POINTS * 24 * 7) return "week"
  return "month"
}

const formatLabel = (time: DateTime, granularity: Granularity, t: TFunction): string => {
  switch (granularity) {
    case "hour":
      // 12h => "12h"
      return time.toFormat(`H'${t("general.date.hour_short")}'`, { locale: i18n.language })
    case "day":
      // 12th Aug => "Mon 12 Aug"
      return time.toFormat("ccc d LLL", { locale: i18n.language })
    case "week":
      // 32th week of 2021 => "Week 32-2021"
      return time.toFormat(`'${t("general.date.week_short")}' W-kkkk`, { locale: i18n.language })
    case "month":
      // August 2021 => "Aug 21"
      return time.toFormat("LLL yy", { locale: i18n.language })
  }
}

const aggregateValue = (data: OrderData, type: GraphType, startIndex: number, endIndex: number): number | null => {
  const revenue = () =>
    data.slice(startIndex, endIndex).reduce((total, item) => {
      return total + parseFloat(item.total_amount.split(" ")[0])
    }, 0)

  const orders = () =>
    data.slice(startIndex, endIndex).reduce((total, item) => {
      return total + item.order_count
    }, 0)

  switch (type) {
    case "revenue":
      return revenue()
    case "orders":
      return orders()
    case "average_order":
      return orders() > 0 ? revenue() / orders() : null
  }
}

export const orderDataDateTime = (item: OrderData[0]): DateTime => {
  const dateTime = PlainDate.fromString(item.date).toDateTimeWithCutoff()
  if (item.hour === undefined) {
    return dateTime
  } else {
    return dateTime.set({ hour: item.hour }).plus(item.hour < cutoffHour() ? { day: 1 } : {})
  }
}
