const create = function (url: string) {
  const data = new FormData()
  const slideFileInput: JQuery<HTMLInputElement> = $("#slide_file_input")
  const files = slideFileInput[0].files

  if (files && files.length > 0) {
    data.append("slide", files[0])

    $.ajax({
      url,
      data,
      cache: false,
      contentType: false,
      processData: false,
      type: "POST",
      success: function (data: string) {
        $("#slides").append(data)
        // Clear file input
        slideFileInput.wrap("<form>").parent("form").trigger("reset")
        slideFileInput.unwrap()
      },
    })
  } else {
    alert("no file selected")
  }
}

const remove = function (button: HTMLElement, url: string) {
  $.ajax({
    url,
    type: "DELETE",
    success: function () {
      $(button).parents(".app-slide").remove()
    },
  })
}

const AppSlides: typeof window.AppSlides = {
  create,
  remove,
}

export default AppSlides
