import { sumoSelect } from "./utils"

const refreshTimezones = (url: string, countrySelect: HTMLSelectElement, timezonesSelector: string) => {
  const countryCode = countrySelect.value
  const timezonesSelect: JQuery<HTMLSelectElement> = $(timezonesSelector)

  $.ajax({
    type: "GET",
    url,
    data: { country: countryCode },
    success: function (data: string) {
      $(timezonesSelect).find("option").remove().end().append(data)
      sumoSelect(timezonesSelect[0]).reload()
    },
  })
}

const LocationForm: typeof window.LocationForm = {
  refreshTimezones,
}

export default LocationForm
