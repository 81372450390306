import { useTranslation } from "react-i18next"

export const PermissionAttributes = [
  "global_admin",
  "global_sales",
  "view_back_office",
  "manage_connections_and_resources",
  "manage_entity",
  "manage_billing",
  "use_orderline",
  "use_catalog_manager",
] as const

export type PermissionAttribute = (typeof PermissionAttributes)[number]

export type Permissions = Record<PermissionAttribute, boolean>

export const emptyPermissions: Permissions = {
  global_admin: false,
  global_sales: false,
  view_back_office: false,
  manage_connections_and_resources: false,
  manage_entity: false,
  manage_billing: false,
  use_orderline: false,
  use_catalog_manager: false,
}

export const isEmptyPermissions = (permissions: Permissions): boolean =>
  Object.values(permissions).every((value) => !value)

export const hasAnyGlobalRole = (permissions: Permissions): boolean =>
  permissions.global_admin || permissions.global_sales

export const hasAnyBackOfficePermission = (permissions: Permissions): boolean =>
  permissions.view_back_office ||
  permissions.manage_connections_and_resources ||
  permissions.manage_entity ||
  permissions.manage_billing

export const hasAnyAppPermission = (permissions: Permissions): boolean =>
  permissions.use_orderline || permissions.use_catalog_manager

export const hasAnyPermission = (permissions: Permissions): boolean =>
  hasAnyGlobalRole(permissions) || hasAnyBackOfficePermission(permissions) || hasAnyAppPermission(permissions)

export type BadgeGroup = "global" | "backoffice" | "app"

export const useBadges = () => {
  const { t } = useTranslation()

  const permissionBadges = (permissions: Permissions): { badges: Array<string>; group: BadgeGroup } => {
    const mapAttributes = (attributes: Array<PermissionAttribute>, group: BadgeGroup) => {
      const badges: Array<string> = []
      attributes.forEach((attr) => {
        if (permissions[attr]) badges.push(t(`roles.permissions.${attr}.badge`))
      })
      return { badges, group }
    }

    if (hasAnyGlobalRole(permissions)) {
      return mapAttributes(["global_admin", "global_sales"], "global")
    } else if (hasAnyBackOfficePermission(permissions)) {
      return mapAttributes(
        ["view_back_office", "manage_connections_and_resources", "manage_entity", "manage_billing"],
        "backoffice",
      )
    } else {
      return mapAttributes(["use_orderline", "use_catalog_manager"], "app")
    }
  }

  return { permissionBadges }
}
