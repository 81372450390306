import { colors } from "@hubrise/react-components"
import { css, styled } from "styled-components"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1.5rem;
`

export const CheckboxLabel = styled.label<{ $inline: boolean }>`
  display: flex;
  gap: 0.75rem;
  align-items: ${(props) => (props.$inline ? "center" : "flex-start")};
`

export const CheckboxInput = styled.input``

export const CheckboxText = styled.div<{ $inline: boolean }>`
  display: flex;
  ${(props) =>
    props.$inline
      ? css`
          flex-direction: row;
          align-items: baseline;
          gap: 0.375rem;
        `
      : css`
          flex-direction: column;
          gap: 0.125rem;
        `}
  user-select: none;
`

export const CheckboxName = styled.div`
  font-weight: 500;
`

export const CheckboxDescription = styled.div<{ $inline: boolean }>`
  font-size: 0.875rem;
  color: ${colors.textMedium};

  ${(props) =>
    props.$inline &&
    css`
      &:before {
        content: "-";
        margin-right: 0.5rem;
      }
    `}
`

export const StyledPermission = styled.div`
  margin-left: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`
