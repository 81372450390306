import { colors, fontSizes, mixin } from "@hubrise/react-components"
import { css, styled } from "styled-components"
import type { RuleSet } from "styled-components"

export const ScrollContainer = styled.div`
  overflow-x: auto;
  overflow-y: hidden;
`

export const Grid = styled.div`
  min-width: 48rem;
  display: grid;
  grid-template-columns: 5rem repeat(24, 1fr);
  gap: 1px;
`

export const EmptyHeader = styled.div``

const cellCss = css`
  height: 1.5rem;
  font-size: ${fontSizes._12};
`

const headerCss = css`
  ${cellCss};
  display: flex;
  align-items: center;

  color: ${colors.textMedium};
  font-weight: 500;
`

export const HourHeader = styled.div`
  ${headerCss};
  justify-content: center;
`

export const DayHeader = styled.div<{ $disabled: boolean }>`
  ${headerCss};
  justify-content: flex-end;
  padding-right: 0.5rem;
  ${(props) => props.$disabled && `color: ${colors.textLighter};`}
`

export const EmptyCell = styled.div<{ $disabled?: boolean; $midnight: boolean; $firstRow: boolean }>`
  background-color: ${(props) => (props.$disabled === true ? "#e0e0e0" : "#f5f5f5")};
  position: relative;

  // Using ::after to create a continuous vertical border that spans across grid gaps, instead of border-left which
  // would be interrupted by the gaps.
  ${(props) =>
    props.$midnight &&
    css`
      &::after {
        content: "";
        position: absolute;
        left: -2px;
        width: 2px;
        top: ${props.$firstRow ? 0 : "-1px"}; // Match grid gap
        height: ${props.$firstRow ? "100%" : "calc(100% + 1px)"};
        background-color: ${props.$disabled === true ? "#ccc" : "#ddd"};
      }
    `}
`

export const Cell = styled(EmptyCell)<{ $intensity: number }>`
  ${cellCss};
  ${mixin.centerElement as RuleSet}; // Temporary fix until we migrate react-components to styled-components v6
  cursor: pointer;

  color: ${(props) => (props.$intensity > 0.5 ? "white" : "#333")};
  background-color: ${(props) => {
    const alpha = 0.2 + props.$intensity * 0.8
    return `rgba(109, 178, 79, ${alpha})`
  }};
`
