import { colors, Icon, iconSizes, sizes, themeColors } from "@hubrise/react-components"
import { styled } from "styled-components"

import { BadgeGroup } from "react-app/Permissions/data/Permissions"

export const BadgeContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.25rem;
`

export const Badge = styled.span<{ $group: BadgeGroup; $clickable: boolean }>`
  padding: 0.15rem 0.35rem;
  border-radius: ${sizes.borderRadius};
  font-size: 0.75rem;
  text-wrap: nowrap;
  ${(props) => props.$clickable && "cursor: pointer;"}

  ${(props) => {
    switch (props.$group) {
      case "global":
        return `
        background-color: #72ae5c10;
        color: #72ae5c;
        border: 1px solid #72ae5c40;
      `
      case "backoffice":
        return `
        background-color: #55555520;
        color: #555555;
        border: 1px solid #55555530;
      `
      case "app":
        return `
        background-color: transparent;
        color: #555555;
        border: 1px solid #55555530;
      `
    }
  }}
`

export const RemoveContent = styled.div``

export const InvitationPending = styled.div<{ $isIconHovered: boolean }>`
  display: flex;
  white-space: nowrap;
  align-items: flex-end;
  color: ${(props) => (props.$isIconHovered ? colors.textDark : colors.textLight)};
`

export const InvitationIcon = styled(Icon).attrs({ size: iconSizes._20 })`
  cursor: pointer;
  padding: 0 0.25rem;

  &:hover {
    color: ${themeColors.primary};
  }
`
