import * as React from "react"
import { useTranslation } from "react-i18next"

import SelfLockingWarning from "react-app/Permissions/SelfLockingWarning"
import {
  ModalContainer,
  ModalContent,
  ModalFooterButtons,
  ModalTitle,
} from "react-app/shared/ManagerModal/commonStyles"
import { ButtonPrimary, ButtonSecondary } from "react-app/shared/commonStyles"
import { bodyData } from "react-app/utils/config"

import { entityNameAndId } from "../data/Entity"
import type { Permissions } from "../data/Permissions"
import { Role, validRole } from "../data/Role"

import Group from "./Group"
import TemplateSelector from "./TemplateSelector"
import { useUiConstants } from "./useUiConstants"

interface EditPermissionsProps {
  userId: string | null
  role: Role
  saveChanges: (role: Role) => void
  close: () => void
}

const EditPermissions = ({ userId, role, saveChanges, close }: EditPermissionsProps): JSX.Element => {
  const { t } = useTranslation()
  const { permissionTemplates, permissionGroups } = useUiConstants()
  const { user_id: selfUserId } = bodyData()

  const [permissions, setPermissions] = React.useState<Permissions>(role.permissions)
  const [selfLockingWarning, setSelfLockingWarning] = React.useState(false)

  const singlePermissions = React.useMemo(
    () => permissionGroups.flatMap(({ permissions }) => permissions),
    [permissionGroups],
  )

  React.useEffect(() => {
    const roleWithUpdatedPermissions: Role = { ...role, permissions }
    permissionGroups.forEach((group) => {
      group.permissions.forEach(({ attribute, enabled }) => {
        const permissionEnabled = enabled(roleWithUpdatedPermissions)
        if (permissionEnabled === "disabledForcedTrue" && !permissions[attribute]) {
          setPermissions((permissions) => ({ ...permissions, [attribute]: true }))
        } else if (permissionEnabled === "disabledForcedFalse" && permissions[attribute]) {
          setPermissions((permissions) => ({ ...permissions, [attribute]: false }))
        }
      })
    })
  }, [role, permissions, setPermissions, permissionGroups])

  const roleWithUpdatedPermissions: Role = { ...role, permissions }
  const availablePermissionTemplates = permissionTemplates.filter(({ enabled }) => enabled(role))

  if (selfLockingWarning) {
    return (
      <SelfLockingWarning
        message={t("roles.self_locking_warning.message_1")}
        onConfirm={() => {
          saveChanges(roleWithUpdatedPermissions)
          close()
        }}
        onCancel={() => setSelfLockingWarning(false)}
      />
    )
  }

  return (
    <ModalContainer>
      <ModalTitle>
        {userId === null
          ? t("roles.edit_permissions.title.user_role", { email: role.user.email })
          : t("roles.edit_permissions.title.entity_role", {
              name: role.entity ? entityNameAndId(role.entity) : "global role",
            })}
      </ModalTitle>

      <ModalContent>
        <TemplateSelector
          {...{
            permissionTemplates: availablePermissionTemplates,
            role: roleWithUpdatedPermissions,
            setPermissions,
            singlePermissions,
          }}
        />

        {permissionGroups.map((group, index) => (
          <Group key={index} {...{ group, role: roleWithUpdatedPermissions, setPermissions }} />
        ))}
      </ModalContent>

      <ModalFooterButtons>
        <ButtonSecondary onClick={close}>{t("roles.edit_permissions.cancel")}</ButtonSecondary>
        <ButtonPrimary
          $disabled={!validRole(roleWithUpdatedPermissions)}
          onClick={() => {
            const newRole: Role = roleWithUpdatedPermissions
            if (!validRole(newRole)) return
            if (
              role.user.id === selfUserId &&
              role.self_locking_entity_permission &&
              !newRole.permissions.manage_entity
            ) {
              setSelfLockingWarning(true)
            } else {
              saveChanges(newRole)
              close()
            }
          }}
        >
          {t("roles.edit_permissions.set_permissions")}
        </ButtonPrimary>
      </ModalFooterButtons>
    </ModalContainer>
  )
}

export default EditPermissions
