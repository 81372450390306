import { modalWidth } from "@hubrise/react-components"
import * as React from "react"
import { useTranslation } from "react-i18next"

import { RemoveContent } from "react-app/Permissions/RoleList/Styles"
import ManagerModal from "react-app/shared/ManagerModal"
import { ModalContainer, ModalContent, ModalFooterButtons } from "react-app/shared/ManagerModal/commonStyles"
import { ButtonSecondary } from "react-app/shared/commonStyles"

interface LockInfoModalProps {
  onClose: () => void
}

const LockInfoModal = ({ onClose }: LockInfoModalProps) => {
  const { t } = useTranslation()

  return (
    <ManagerModal onClose={onClose} maxWidth={modalWidth.medium}>
      <ModalContainer>
        <ModalContent>
          <RemoveContent>{t("roles.list.locked")}</RemoveContent>
        </ModalContent>

        <ModalFooterButtons>
          <ButtonSecondary onClick={onClose}>{t("general.close")}</ButtonSecondary>
        </ModalFooterButtons>
      </ModalContainer>
    </ManagerModal>
  )
}

export default LockInfoModal
