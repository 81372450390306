import * as React from "react"
import { useTranslation } from "react-i18next"

import { emptyPermissions, Permissions } from "react-app/Permissions/data/Permissions"
import { Role } from "react-app/Permissions/data/Role"

import { PermissionTemplate, SinglePermission } from "../types"

import { Container, Description, Label, Name, Option, StyledSelect } from "./Styles"

interface TemplateSelectorProps {
  permissionTemplates: PermissionTemplate[]
  role: Role
  setPermissions: React.Dispatch<React.SetStateAction<Permissions>>
  singlePermissions: Array<SinglePermission>
}

const TemplateSelector = ({
  permissionTemplates,
  role,
  setPermissions,
  singlePermissions,
}: TemplateSelectorProps): JSX.Element => {
  const { t } = useTranslation()

  const { permissions } = role

  const matchesTemplate = (permissions: Permissions, template: PermissionTemplate) => {
    return singlePermissions.every(({ attribute, enabled }) => {
      switch (enabled(role)) {
        case "enabled":
          return permissions[attribute] === template.permissions.includes(attribute)
        case "disabledForcedTrue":
          return permissions[attribute]
        case "disabledForcedFalse":
          return !permissions[attribute]
      }
    })
  }

  const applyTemplate = (template: PermissionTemplate) => {
    const newPermissions: Permissions = { ...emptyPermissions }
    template.permissions.forEach((perm) => (newPermissions[perm] = true))
    setPermissions(newPermissions)
  }

  const templateId = permissionTemplates.findIndex((template) => matchesTemplate(permissions, template))

  return (
    <Container>
      <Label>{t("roles.edit_permissions.templates") + t("general.colon")}</Label>
      <StyledSelect
        value={[templateId === -1 ? "" : String(templateId)]}
        onChange={(value) => value[0] !== "" && applyTemplate(permissionTemplates[Number(value[0])])}
        searchable={false}
        options={[
          ...(templateId === -1 ? [{ label: t("roles.templates.custom.name"), value: "" }] : []),
          ...permissionTemplates.map((template, index) => ({
            label: template.name,
            value: String(index),
          })),
        ]}
        renderOption={(option) => (
          <Option>
            <Name>{option.label}</Name>
            {option.value !== "" && <Description>{permissionTemplates[Number(option.value)].description}</Description>}
          </Option>
        )}
      />
    </Container>
  )
}

export default TemplateSelector
