import { fontSizes } from "@hubrise/react-components"
import { styled } from "styled-components"

export const Progression = styled.div<{ $isPositive: boolean }>`
  display: flex;
  align-items: start;
  font-weight: 500;
  font-size: ${fontSizes._14};
  color: ${(props) => (props.$isPositive ? "#4CAF50" : "#F44336")};
  cursor: pointer;
`

export const ToolTipContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

export const ToolTipPreviousValue = styled.span`
  margin-left: 0.5rem;
  font-weight: bold;
`
