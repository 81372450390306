export type EntityIds = { accountId: string; locationId: string | null }

export type Entity = {
  account: {
    id: string
    name: string
  }
  location?: {
    id: string
    name: string
  }
}

export const accountNameAndId = (entity: Entity): string => {
  const { account } = entity
  return `${account.name} (${account.id})`
}

export const locationNameAndId = (entity: Entity): string | null => {
  const { location } = entity
  return location ? `${location.name} (${location.id})` : null
}

export const entityNameAndId = (entity: Entity): string => {
  const { account, location } = entity
  return location ? `${account.name} ${location.name} (${location.id})` : accountNameAndId(entity)
}

export const entityId = (entity: Entity): string => {
  const { account, location } = entity
  return location ? location.id : account.id
}

export const entityParamsAsString = (entity: Entity): string => {
  const { account, location } = entity
  return location ? `location_id=${location.id}` : `account_id=${account.id}`
}
