import { modalWidth, useOnOutsideClick } from "@hubrise/react-components"
import * as React from "react"

import { ClickableOverlay, ScrollOverlay, StyledModal } from "./Styles"

interface ManagerModalProps {
  children: React.ReactNode
  onClose: () => void
  maxWidth?: string
}

const ManagerModal = ({ children, onClose, maxWidth = modalWidth.large }: ManagerModalProps): JSX.Element | null => {
  const $clickableOverlayRef = React.useRef<HTMLDivElement>(null)
  const $modalRef = React.useRef<HTMLDivElement>(null)
  useOnOutsideClick([$modalRef], true, onClose, $clickableOverlayRef)

  React.useLayoutEffect(() => {
    const rootElement = document.getElementsByTagName("html")[0]

    if (rootElement !== undefined) {
      const previousPosition = rootElement.style.position
      const previousOverflow = rootElement.style.overflow

      rootElement.style.position = "relative"
      rootElement.style.overflow = "hidden"

      return () => {
        rootElement.style.position = previousPosition
        rootElement.style.overflow = previousOverflow
      }
    }
  }, [])

  return (
    <ScrollOverlay>
      <ClickableOverlay ref={$clickableOverlayRef}>
        <StyledModal ref={$modalRef} $maxWidth={maxWidth}>
          {children}
        </StyledModal>
      </ClickableOverlay>
    </ScrollOverlay>
  )
}

export default ManagerModal
