import * as React from "react"
import { useTranslation } from "react-i18next"

import { ButtonPrimary } from "react-app/shared/commonStyles"
import { useMinDisplay } from "react-app/utils/hooks"
import { checkEmailFormat } from "react-app/utils/utils"

import { EntityIds } from "../data/Entity"
import { isEmptyPermissions } from "../data/Permissions"
import { Role, RoleIds } from "../data/Role"
import { useFindUserByEmail, useFindRole } from "../data/queries"

import InvitationModal from "./InvitationModal"
import { Container, StyledIcon, Input, InputWrapper, Info, PlusIcon, SearchBox, Spacer } from "./Styles"

type Status = "searching" | "not_found" | "empty" | "found" | "not_an_email"

interface AddUserToEntityProps {
  setEditingRole: (role: Role) => void
  email: string | null
  entityIds: EntityIds
  setEmail: (email: string | null) => void
}

const AddUserToEntity = ({ setEditingRole, email, entityIds, setEmail }: AddUserToEntityProps): JSX.Element => {
  const { t } = useTranslation()

  const { data: user, isLoading } = useFindUserByEmail(email)
  const showLoading = useMinDisplay(isLoading, 250)

  const [showInvitationModal, setShowInvitationModal] = React.useState(false)

  const editingRoleIds: RoleIds | null = user
    ? {
        userId: user.id,
        ...entityIds,
      }
    : null
  const { data: editingRole, error: editingRoleError } = useFindRole(editingRoleIds)

  if (editingRoleError) {
    return <div>{editingRoleError.message}</div>
  }

  const status = ((): Status => {
    if (email === null) return "empty"
    if (!checkEmailFormat(email)) return "not_an_email"
    if (showLoading) return "searching"
    if (user) return "found"
    return "not_found"
  })()

  const statusIcon = {
    searching: <i className="fa fa-spinner fa-spin"></i>,
    not_found: <i className="fa fa-times"></i>,
    empty: <i className="fa fa-search"></i>,
    found: <i className="fa fa-check"></i>,
    not_an_email: <i className="fa fa-times"></i>,
  }[status]

  const statusInfo = {
    searching: () => t("roles.add_role.status.searching"),
    not_found: () => t("roles.add_role.status.user_not_found"),
    empty: () => null,
    found: () => user!.full_name,
    not_an_email: () => t("roles.add_role.status.not_an_email"),
  }[status]()

  return (
    <Container className="card">
      <SearchBox>
        <InputWrapper>
          <Input
            type="email"
            placeholder={t("roles.add_role.email_placeholder")}
            value={email ?? ""}
            onChange={(e) => setEmail(e.target.value !== "" ? e.target.value.toLocaleLowerCase() : null)}
          />
        </InputWrapper>
        <StyledIcon>{statusIcon}</StyledIcon>
      </SearchBox>

      {statusInfo !== null && <Info $highlight={status === "found"}>{statusInfo}</Info>}

      <Spacer />

      {(() => {
        if (status === "not_found") {
          return (
            <ButtonPrimary onClick={() => setShowInvitationModal(true)}>
              {t("roles.add_role.invite_user")}
            </ButtonPrimary>
          )
        } else if (editingRole) {
          return (
            <ButtonPrimary onClick={() => setEditingRole(editingRole)}>
              {isEmptyPermissions(editingRole.permissions)
                ? t("roles.add_role.add_user")
                : t("roles.add_role.edit_permissions")}
            </ButtonPrimary>
          )
        } else {
          return (
            <PlusIcon>
              <i className="fa fa-plus"></i>
            </PlusIcon>
          )
        }
      })()}

      {showInvitationModal && (
        <InvitationModal
          email={email!}
          entityIds={entityIds}
          onCancel={() => setShowInvitationModal(false)}
          onSend={(role) => {
            setEditingRole(role)
            setShowInvitationModal(false)
            setEmail(null)
          }}
        />
      )}
    </Container>
  )
}

export default AddUserToEntity
