import * as React from "react"

import { Permissions } from "react-app/Permissions/data/Permissions"
import { Role } from "react-app/Permissions/data/Role"

import { PermissionGroup } from "../types"

import Permission from "./Permission"
import { CheckboxDescription, CheckboxInput, CheckboxLabel, CheckboxName, CheckboxText, Container } from "./Styles"

interface GroupProps {
  group: PermissionGroup
  role: Role
  setPermissions: React.Dispatch<React.SetStateAction<Permissions>>
}

const Group = ({ group, role, setPermissions }: GroupProps): JSX.Element => {
  const { permissions } = role

  const nonHiddenPermissions = group.permissions.filter(({ enabled }) => enabled(role) !== "disabledForcedFalse")

  const allChecked = nonHiddenPermissions.every(({ attribute }) => permissions[attribute])
  const disabled = nonHiddenPermissions.every(({ enabled }) => enabled(role) !== "enabled")

  const togglePermissionGroup = () => {
    const newPermissions: Permissions = { ...permissions }

    const allEnabled = nonHiddenPermissions.every(({ attribute }) => permissions[attribute])
    nonHiddenPermissions.forEach(({ attribute }) => (newPermissions[attribute] = !allEnabled))

    setPermissions(newPermissions)
  }

  const inline = { $inline: true }
  return (
    <Container key={group.id}>
      <CheckboxLabel {...inline}>
        <CheckboxInput type="checkbox" checked={allChecked} onChange={togglePermissionGroup} disabled={disabled} />
        <CheckboxText {...inline}>
          <CheckboxName>{group.name}</CheckboxName>
          {group.description && <CheckboxDescription {...inline}>{group.description}</CheckboxDescription>}
        </CheckboxText>
      </CheckboxLabel>

      {group.permissions.map((permission) => (
        <Permission key={permission.attribute} {...{ permission, role, permissions, setPermissions }} />
      ))}
    </Container>
  )
}

export default Group
