import { colors, Select } from "@hubrise/react-components"
import { styled } from "styled-components"

export const Container = styled.div`
  margin-bottom: 1.5rem;
  display: flex;
  align-items: baseline;
  gap: 1rem;
`

export const Label = styled.div`
  white-space: nowrap;
`

export const StyledSelect = styled(Select)``

export const Option = styled.div``

export const Name = styled.div`
  font-weight: 500;
`

export const Description = styled.div`
  font-size: 0.875rem;
  color: ${colors.textMedium};
`
