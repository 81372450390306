import { sumoSelect } from "./utils"

const onRoleChanged = (select: HTMLSelectElement): void => {
  const parent = $(select).parents("form")
  const accountSelect = parent.find<HTMLSelectElement>("select[name=role_account_id]")
  const locationSelect = parent.find<HTMLSelectElement>("select[name=role_location_id]")
  const isGlobal = $(select).find(":selected").data("global") !== false

  if (isGlobal) {
    sumoSelect(accountSelect[0]).disable()
    sumoSelect(locationSelect[0]).disable()
  } else {
    sumoSelect(accountSelect[0]).enable()
    sumoSelect(locationSelect[0]).enable()
  }
}

const onAccountChanged = (select: HTMLSelectElement): void => {
  const parent = $(select).parents("form")
  const selectedAccount = parent.find<HTMLSelectElement>("select[name=role_account_id] option:selected").val() as string
  const locationSelect = parent.find<HTMLSelectElement>("select[name=role_location_id]")

  locationSelect.find("option").attr("disabled", "disabled")
  locationSelect.find("option[data-account=" + selectedAccount + "]").removeAttr("disabled")
  locationSelect.find("option[data-blank=true]").show().prop("selected", true)

  sumoSelect(locationSelect[0]).reload()
}

const UserRolesForm: typeof window.UserRolesForm = {
  onRoleChanged,
  onAccountChanged,
}

export default UserRolesForm
