import "react-datepicker/dist/react-datepicker.css"
import * as React from "react"
import DatePicker from "react-datepicker"

import PlainDate from "react-app/utils/PlainDate"
import { entityCreatedDate, getCurrentLocale, getDateFormatPattern } from "react-app/utils/utils"

import { StyledPicker, Wrapper } from "./Styles"

interface DateRangePickerProps {
  initialStartDate: PlainDate
  initialEndDate: PlainDate
  onTimeChange: (start: PlainDate, end: PlainDate) => void
}

const DateRangePicker = ({ initialStartDate, initialEndDate, onTimeChange }: DateRangePickerProps): JSX.Element => {
  const [startDate, setStartDate] = React.useState<Date | null>(initialStartDate.toJSDateBrowserTZ())
  const [endDate, setEndDate] = React.useState<Date | null>(initialEndDate.toJSDateBrowserTZ())

  return (
    <StyledPicker>
      <Wrapper $effectOnFocus={true}>
        <DatePicker
          dateFormat={getDateFormatPattern(getCurrentLocale())}
          selected={startDate}
          onChange={(dates) => {
            const [start, end] = dates
            setStartDate(start)
            setEndDate(end)
            if (start && end) {
              onTimeChange(PlainDate.fromJSDateBrowserTZ(start), PlainDate.fromJSDateBrowserTZ(end))
            }
          }}
          startDate={startDate || undefined}
          endDate={endDate || undefined}
          excludeDateIntervals={[
            { start: new Date(2000, 1, 1), end: entityCreatedDate().toJSDateBrowserTZ() },
            { start: new Date(), end: new Date(2099, 1, 1) },
          ]}
          selectsRange
          monthsShown={1}
        />
      </Wrapper>
    </StyledPicker>
  )
}

export default DateRangePicker
