import Foundation from "foundation-sites"

const init = (mainElement: JQuery): void => {
  new Foundation.Accordion(mainElement, {
    allowAllClosed: true,
  })

  mainElement
    .find<HTMLLinkElement>("[data-card-foldable-toggle],[data-card-foldable-header]")
    .on("click", (e: JQuery.ClickEvent) => {
      e.preventDefault()
      e.stopPropagation()
      mainElement.foundation("toggle", mainElement.find("[data-card-foldable-content]"))
    })
}

const CardFoldable: typeof window.CardFoldable = {
  init,
}

export default CardFoldable
