const initCopiableCells = (): void => {
  let activeCell: HTMLTableCellElement | null = null
  let copyIcon: HTMLElement | null = null

  function createCopyIcon(): HTMLElement {
    const icon = document.createElement("i")
    icon.className = "fa fa-copy copy-icon"
    icon.style.cssText = `
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      cursor: pointer;
      padding: 4px;
      font-size: 14px;
      background-color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0.5;
      transition: opacity 0.2s ease;
    `
    icon.addEventListener("mouseover", () => {
      icon.style.opacity = "1"
    })
    icon.addEventListener("mouseout", () => {
      icon.style.opacity = "0.5"
    })
    return icon
  }

  function showCopyMessage(element: HTMLElement): void {
    const messageElement = document.createElement("span")
    messageElement.textContent = "Copied to clipboard (admin only)"
    messageElement.style.cssText = `
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(51, 51, 51, 0.9);
      color: white;
      padding: 4px 8px;
      font-size: 12px;
      opacity: 0;
      transition: opacity 0.1s ease;
      text-align: center;
      z-index: 1000;
      pointer-events: none;
    `
    element.appendChild(messageElement)
    setTimeout(() => {
      messageElement.style.opacity = "1"
    }, 10)

    const copyMessageTimeout = 1500
    setTimeout(() => {
      messageElement.style.opacity = "0"
      setTimeout(() => messageElement.remove(), 100)
    }, copyMessageTimeout)
  }

  function copyTextToClipboard(text: string, cell: HTMLTableCellElement): void {
    const textArea = document.createElement("textarea")
    textArea.value = text
    textArea.style.position = "fixed"
    textArea.style.left = "-9999px"
    textArea.style.top = "0"
    document.body.appendChild(textArea)
    textArea.focus()
    textArea.select()

    try {
      const successful = document.execCommand("copy")
      if (!successful) {
        throw new Error("Copy command was unsuccessful")
      }
      showCopyMessage(cell)
    } catch (err) {
      console.error("Failed to copy text: ", err)
    }

    document.body.removeChild(textArea)
  }

  function showCopyIcon(cell: HTMLTableCellElement): void {
    if (activeCell !== cell) {
      hideCopyIcon()
      activeCell = cell
      copyIcon = createCopyIcon()
      cell.style.position = "relative"
      cell.insertBefore(copyIcon, cell.firstChild)
    }
  }

  function hideCopyIcon(): void {
    if (copyIcon && activeCell) {
      copyIcon.remove()
      copyIcon = null
      activeCell = null
    }
  }

  document.body.addEventListener("mouseover", (event: MouseEvent) => {
    const target = event.target as HTMLElement
    const cell = target.closest("td")
    if (cell && cell.querySelector("[data-copiable-cell]")) {
      showCopyIcon(cell)
    }
  })

  document.body.addEventListener("mouseout", (event: MouseEvent) => {
    const target = event.target as HTMLElement
    const relatedTarget = event.relatedTarget as HTMLElement
    const cell = target.closest("td")
    if (cell && !cell.contains(relatedTarget)) {
      hideCopyIcon()
    }
  })

  document.body.addEventListener("click", (event: MouseEvent) => {
    const target = event.target as HTMLElement
    if (target.classList.contains("copy-icon")) {
      event.stopPropagation()
      event.preventDefault()

      const cell = target.closest("td") as HTMLTableCellElement
      const copiableElement = cell.querySelector("[data-copiable-cell]")
      if (copiableElement) {
        // Use the custom text if available
        const customText = copiableElement.getAttribute("data-copiable-text")
        if (customText !== null) {
          copyTextToClipboard(customText, cell)
          return
        }

        // Use the actual text content of the element
        const parts = copiableElement.textContent?.trim().split(/\n+/) ?? []
        let textToCopy = ""
        if (parts.length === 1) {
          textToCopy = parts[0]
        } else if (parts.length >= 2) {
          textToCopy = `${parts[0]} (${parts[1]})`
        }
        copyTextToClipboard(textToCopy, cell)
      }
    }
  })
}

export default initCopiableCells
