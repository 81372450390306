import * as React from "react"

import PlainDate from "react-app/utils/PlainDate"
import { Hour, Day, useOrderData } from "react-app/utils/queries/OrderData"

type HeatmapOrderData = Map<
  Day,
  Map<
    Hour,
    {
      orderCount: number
      totalAmount: number
    }
  >
>

export type UseHeatmapOrderDataResult = {
  data: HeatmapOrderData
  isLoading: boolean
  error: Error | null
}

export const useHeatmapOrderData = (startDate: PlainDate, endDate: PlainDate): UseHeatmapOrderDataResult => {
  const { data, isLoading, error } = useOrderData(
    startDate.toDateTimeWithCutoff(),
    endDate.toDateTimeWithCutoff().plus({ day: 1 }),
    {
      groupBy: "weekday-hour",
    },
  )

  const processedData: HeatmapOrderData = React.useMemo<HeatmapOrderData>(() => {
    if (isLoading || error || !data) return new Map()

    // data = [{"weekday":"wednesday","hour":11,"order_count":2,"total_amount":"76.69 EUR"}, ...
    const result: HeatmapOrderData = new Map()

    data.forEach((item) => {
      const weekday = item.weekday!
      const hour = item.hour!
      if (result.get(weekday) === undefined) {
        result.set(weekday, new Map())
      }
      result.get(weekday)!.set(hour, {
        orderCount: item.order_count,
        totalAmount: parseFloat(item.total_amount),
      })
    })

    return result
  }, [data, error, isLoading])

  return {
    data: processedData,
    isLoading,
    error,
  }
}
