import { Entity } from "./Entity"
import {
  hasAnyBackOfficePermission,
  hasAnyGlobalRole,
  hasAnyPermission,
  PermissionAttribute,
  Permissions,
} from "./Permissions"
import { User } from "./User"

export type Role = {
  id: string | null
  user: User
  entity: Entity | null
  permissions: Permissions
  self_locking_entity_permission: boolean
}

export type RoleIds = {
  userId: string
  accountId: string | null
  locationId: string | null
}

// Keep this method in sync with #validate_role in API.
export const validRole = (role: Role): boolean => {
  const { permissions } = role
  const { account, location } = role.entity ?? {}

  // Global roles have no entities, non-global roles have entities
  if (
    (hasAnyGlobalRole(permissions) && (account || location)) ||
    (!hasAnyGlobalRole(permissions) && !account && !location)
  ) {
    return false
  }

  // Cannot combine global roles
  if (permissions.global_admin && permissions.global_sales) {
    return false
  }

  // Back-office permissions automatically grant app access
  if (
    hasAnyBackOfficePermission(permissions) &&
    !permissions.use_orderline &&
    (!permissions.use_catalog_manager || location)
  ) {
    return false
  }

  // View back-office permission is required for all other back-office permissions
  if (hasAnyBackOfficePermission(permissions) && !permissions.view_back_office) {
    return false
  }

  // Catalog Manager permission cannot be granted at location level
  if (permissions.use_catalog_manager && location) {
    return false
  }

  // At least one permission must be granted
  if (!hasAnyPermission(permissions)) {
    return false
  }

  return true
}

export const togglePermission = (role: Role, permission: PermissionAttribute): Permissions => {
  const { permissions } = role
  const { location } = role.entity ?? {}
  const newPermissions: Permissions = { ...permissions, [permission]: !permissions[permission] }

  // If turning off view_back_office, turn off all back office permissions
  if (permission === "view_back_office" && !newPermissions.view_back_office) {
    newPermissions.manage_connections_and_resources = false
    newPermissions.manage_entity = false
    newPermissions.manage_billing = false
  }

  // If turning on any back office permission, turn on view_back_office
  if (
    ["manage_connections_and_resources", "manage_entity", "manage_billing"].includes(permission) &&
    newPermissions[permission]
  ) {
    newPermissions.view_back_office = true
  }

  // If turning on any back office permission, ensure app permissions are on
  if (
    ["view_back_office", "manage_connections_and_resources", "manage_entity", "manage_billing"].includes(permission) &&
    newPermissions[permission] &&
    (!permissions.use_orderline || !permissions.use_catalog_manager)
  ) {
    newPermissions.use_orderline = true
    newPermissions.use_catalog_manager = !location
  }

  // If turning on global_admin, turn off global_sales (and vice versa)
  if (permission === "global_admin" && newPermissions.global_admin) {
    newPermissions.global_sales = false
  }
  if (permission === "global_sales" && newPermissions.global_sales) {
    newPermissions.global_admin = false
  }

  return newPermissions
}
