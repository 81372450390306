import jsonFormatHighlight from "json-format-highlight"

const toggleDetails = function (_rowGeneralNode: HTMLElement, url: string) {
  const rowGeneralNode = $(_rowGeneralNode)

  const rowDetailsNode = rowGeneralNode.next(".tbody__row_expandable")
  const initialized = rowGeneralNode.data("details-initialized") !== undefined

  if (!initialized) {
    $.ajax({
      url,
      dataType: "text",
      success: function (data: string) {
        rowDetailsNode.html(data)
        initDetails(rowDetailsNode)
        rowGeneralNode.data("details-initialized", true)
      },
    })
  }

  if (rowGeneralNode.attr("data-row-expanded") === "") {
    rowGeneralNode.removeAttr("data-row-expanded")
  } else {
    rowGeneralNode.attr("data-row-expanded", "")
  }
}

const initDetails = function (rowDetailsNode: JQuery) {
  rowDetailsNode.find("code[data-code]").each(function () {
    highlightBlock(this)
  })

  rowDetailsNode.find(".log-table__callbacks-link").click(function () {
    toggleCallbacks(this)
    return false
  })
  rowDetailsNode.find(".connection-log__failures-link").click(function () {
    showCallbackFailures(this)
    return false
  })

  // Foundation tabs
  const element = rowDetailsNode.find("[data-connection-log-tabs]")
  const options = {
    linkClass: "connection-log__tabs-title",
    panelClass: "connection-log__tabs-panel",
  }
  if (element.length) {
    new Foundation.Tabs(element, options)
  }
}

const toggleCallbacks = function (_link: HTMLElement) {
  const link = $(_link)
  const callbackDetails = link.parent().find(".logs-table__callbacks_details")

  callbackDetails.toggle()
  link.find(".logs-table__toggle-open").toggle()
  link.find(".logs-table__toggle-close").toggle()
}

const showAll = function (url: string, link: HTMLElement, loadingText: string) {
  $(link).text(loadingText).addClass("log-section__show-all_loading")
  $.ajax({
    url,
    dataType: "text",
    success: function (data: string) {
      const codeNode = $(link).parent(".log-section__content_code").find("[data-code]")
      codeNode.text(data)
      highlightBlock(codeNode[0], { force: true })
      $(link).hide()
    },
  })
}

const showCallbackFailures = function (link: HTMLElement) {
  $(link).parent(".connection-log__failures").find(".connection-log__failures-list").show()
}

export const highlightBlock = (_block: HTMLElement, options: { force: boolean } = { force: false }) => {
  const block = $(_block)
  if (block.attr("data-code-highlighted") !== undefined && !options.force) return
  block.attr("data-code-highlighted", "true")
  const html = jsonFormatHighlight(block.text(), {
    keyColor: "#e83244",
    numberColor: "#008080",
    stringColor: "#e83244",
    trueColor: "#333",
    falseColor: "#333",
    nullColor: "#333",
  })
  block.html(html)
}

const ConnectionLogs: typeof window.ConnectionLogs = {
  toggleDetails,
  showAll,
}

export default ConnectionLogs
