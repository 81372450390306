import * as React from "react"

import { Role } from "react-app/Permissions/data/Role"
import { useCreateRole, useRemoveRole } from "react-app/Permissions/data/mutations"
import { useRoles } from "react-app/Permissions/data/queries"
import ManagerModal from "react-app/shared/ManagerModal"
import { entityIds } from "react-app/utils/utils"

import RoleList from "././RoleList"
import AddEntityToUser from "./AddRole/AddEntityToUser"
import AddUserToEntity from "./AddRole/AddUserToEntity"
import EditPermissions from "./EditPermissions"
import { Container } from "./Styles"

export type PermissionContext =
  | {
      page: "profile" | "user"
      userId: string
    }
  | {
      page: "entity"
      userId: null
    }

interface PermissionProps {
  context: PermissionContext
}

const Permissions = ({ context }: PermissionProps): JSX.Element => {
  const { userId } = context
  const [emailOrQuery, setEmailOrQuery] = React.useState<string | null>(null)

  const { data: roles, error: rolesError } = useRoles(userId)
  const { mutate: createRole } = useCreateRole(userId)
  const { mutate: removeRole } = useRemoveRole(userId)

  const [editingRole, setEditingRole] = React.useState<Role | null>(null)

  if (rolesError?.status === 401) {
    return <></>
  } else if (rolesError) {
    return <div>{rolesError.message}</div>
  }

  return (
    <Container>
      <RoleList
        userId={userId}
        canUpdate={context.page !== "profile"}
        createAccountLink={context.page === "profile"}
        roles={roles ?? []}
        editRole={(role) => {
          setEmailOrQuery(null)
          setEditingRole(role)
        }}
        removeRole={(role) => {
          setEmailOrQuery(null)
          void removeRole(role.id!)
        }}
      />

      {context.page !== "profile" &&
        (userId === null ? (
          <AddUserToEntity
            setEditingRole={setEditingRole}
            entityIds={entityIds()}
            email={emailOrQuery}
            setEmail={setEmailOrQuery}
          />
        ) : (
          <AddEntityToUser
            userId={userId}
            setEditingRole={setEditingRole}
            query={emailOrQuery}
            setQuery={setEmailOrQuery}
          />
        ))}

      {editingRole && (
        <ManagerModal onClose={() => setEditingRole(null)}>
          <EditPermissions
            userId={userId}
            role={editingRole}
            saveChanges={(role) => {
              setEmailOrQuery(null)
              void createRole(role)
            }}
            close={() => setEditingRole(null)}
          />
        </ManagerModal>
      )}
    </Container>
  )
}

export default Permissions
