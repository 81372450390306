import { useQuery } from "@tanstack/react-query"
import { DateTime } from "luxon"

import { entityId } from "react-app/utils/utils"

import { buildUrlWithTimes } from "./common"

export type ProductData = Array<{
  product_name: string
  order_count: number
  total_quantity: number
  total_revenue: string
}>

export const useProductData = (startTime: DateTime, endTime: DateTime, { limit }: { limit: number }) => {
  return useQuery<ProductData>({
    queryKey: ["ProductData", startTime, endTime, limit],
    queryFn: async () => {
      // The server only sorts by total_quantity. We need this extra step to guarantee a stable sort order, for example
      // when switching between viewAll and not viewAll.
      const unsortedData = await fetchProductData(startTime, endTime, limit)
      return stableSortProductData(unsortedData)
    },
  })
}

const fetchProductData = async (startTime: DateTime, endTime: DateTime, limit: number): Promise<ProductData> => {
  const params = new URLSearchParams({
    order_by: "total_quantity",
    limit: limit.toString(),
  })

  const url = buildUrlWithTimes(`/api/entities/${entityId()}/analytics/products`, startTime, endTime, params)

  const response = await fetch(url.toString())
  if (!response.ok) {
    throw new Error(`Failed to fetch: ${response.statusText}`)
  }
  return response.json() as Promise<ProductData>
}

const stableSortProductData = (data: ProductData): ProductData => {
  return data.sort((a, b) => {
    if (a.total_quantity !== b.total_quantity) return b.total_quantity - a.total_quantity
    if (a.total_revenue !== b.total_revenue) return parseFloat(b.total_revenue) - parseFloat(a.total_revenue)
    return a.product_name.localeCompare(b.product_name)
  })
}
