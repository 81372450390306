import type { IconCode } from "@hubrise/react-components"
import * as React from "react"
import { useTranslation } from "react-i18next"

import { InvitationIcon, InvitationPending } from "react-app/Permissions/RoleList/Styles"
import { User } from "react-app/Permissions/data/User"

const UserTd = ({ user, showInvitationDetails }: { user: User; showInvitationDetails: (user: User) => void }) => {
  const { t } = useTranslation()
  const [isIconHovered, setIsIconHovered] = React.useState(false)

  const name = user.invitation_pending ? (
    <InvitationPending $isIconHovered={isIconHovered}>
      {t("roles.list.invitation_pending")}

      <InvitationIcon
        code={"history" as IconCode}
        onMouseEnter={() => setIsIconHovered(true)}
        onMouseLeave={() => setIsIconHovered(false)}
        onClick={(event) => {
          event.preventDefault()
          showInvitationDetails(user)
        }}
      />
    </InvitationPending>
  ) : (
    user.full_name
  )

  return window.pageConfig.isGlobalRoleUser ? (
    <td className="tbody__cell tbody__cell_link">
      <a className="tbody__link" href={`/admin/users/${user.id}`}>
        {name}
      </a>
    </td>
  ) : (
    <td className="tbody__cell">{name}</td>
  )
}

export default UserTd
