import { breakpoints, colors, themeColors } from "@hubrise/react-components"
import { styled } from "styled-components"

export const Container = styled.div`
  padding: 0.375rem 1rem 0.375rem 1.5rem;
  min-height: 2.25rem;
  display: flex;
  align-items: center;
  gap: 2rem;
`

export const SearchBox = styled.div`
  position: relative;
`

export const InputWrapper = styled.div.attrs({ className: "form__input-wrapper" })`
  position: relative;
  top: 0.15em;
  margin-left: 1.5rem;
`

export const Input = styled.input.attrs({ className: "form__input-text" })`
  width: 20rem;
`

export const StyledIcon = styled.div`
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
`

export const Info = styled.div<{ $highlight: boolean }>`
  white-space: nowrap;
  max-width: 15rem;
  text-overflow: ellipsis;
  color: ${(props) => (props.$highlight ? colors.textDark : colors.textLight)};
`

export const Spacer = styled.div`
  display: none;

  @media (min-width: ${breakpoints.tablet}) {
    display: block;
    flex: 1;
  }
`

export const PlusIcon = styled.div`
  padding: 0.5rem 0.625rem 0.625rem;
  margin-right: 0.75rem;
  cursor: pointer;

  &:hover {
    color: ${themeColors.primary};
  }
`
