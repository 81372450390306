import { modalWidth } from "@hubrise/react-components"
import * as React from "react"
import { useTranslation } from "react-i18next"

import { entityNameAndId } from "react-app/Permissions/data/Entity"
import { Role } from "react-app/Permissions/data/Role"
import ManagerModal from "react-app/shared/ManagerModal"
import {
  ModalContainer,
  ModalContent,
  ModalFooterButtons,
  ModalTitle,
} from "react-app/shared/ManagerModal/commonStyles"
import { ButtonPrimary, ButtonSecondary } from "react-app/shared/commonStyles"

import { RemoveContent } from "../Styles"

interface ConfirmRemoveModalProps {
  userId: string | null
  role: Role
  onCancel: () => void
  onConfirm: (role: Role) => void
}

const ConfirmRemoveModal = ({ userId, role, onCancel, onConfirm }: ConfirmRemoveModalProps) => {
  const { t } = useTranslation()

  return (
    <ManagerModal onClose={onCancel} maxWidth={modalWidth.medium}>
      <ModalContainer>
        <ModalTitle>{t("roles.remove.title")}</ModalTitle>

        <ModalContent>
          <RemoveContent>
            {t("roles.remove.description", {
              name: userId === null ? role.user.email : entityNameAndId(role.entity!),
            })}
          </RemoveContent>
        </ModalContent>

        <ModalFooterButtons>
          <ButtonSecondary onClick={onCancel}>{t("general.cancel")}</ButtonSecondary>
          <ButtonPrimary onClick={() => onConfirm(role)}>{t("general.remove")}</ButtonPrimary>
        </ModalFooterButtons>
      </ModalContainer>
    </ManagerModal>
  )
}

export default ConfirmRemoveModal
