import * as React from "react"

export const useDebounce = <T>(value: T, delay = 500): T => {
  const [debouncedValue, setDebouncedValue] = React.useState(value)

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)

    return () => clearTimeout(timer)
  }, [value, delay])

  return debouncedValue
}

export const useMinDisplay = (value: boolean, minDuration = 500): boolean => {
  const [overrideWithTrue, setOverrideWithTrue] = React.useState<boolean>(false)

  React.useEffect(() => {
    if (value) {
      setOverrideWithTrue(true)
      setTimeout(() => setOverrideWithTrue(false), minDuration)
    }
  }, [value, minDuration])

  return overrideWithTrue || value
}
