import * as React from "react"
import { useTranslation } from "react-i18next"

import { bodyData } from "react-app/utils/config"

import { useBadges } from "../data/Permissions"
import { Role } from "../data/Role"
import { User } from "../data/User"

import { Badge, BadgeContainer } from "./Styles"
import EntityTd from "./list/EntityTd"
import UserTd from "./list/UserTd"
import ConfirmRemoveModal from "./modals/ConfirmRemoveModal"
import InvitationSentModal from "./modals/InvitationSentModal"
import LockInfoModal from "./modals/LockInfoModal"
import SelfLockingWarningModal from "./modals/SelfLockingWarningModal"

interface RoleListProps {
  userId: string | null
  canUpdate: boolean
  createAccountLink: boolean
  roles: Array<Role>
  editRole: (role: Role) => void
  removeRole: (role: Role) => void
}

const RoleList = ({
  userId,
  canUpdate,
  createAccountLink,
  roles,
  editRole,
  removeRole,
}: RoleListProps): JSX.Element => {
  const { t } = useTranslation()
  const { permissionBadges } = useBadges()
  const { user_id: selfUserId } = bodyData()

  const [deletingUserRole, setDeletingUserRole] = React.useState<Role | null>(null)
  const [showLockModal, setShowLockModal] = React.useState(false)
  const [showLockingWarning, setShowLockingWarning] = React.useState(false)
  const [showInvitationDetailsUser, setShowInvitationDetailsUser] = React.useState<User | null>(null)

  return (
    <>
      <div className="card">
        <div className="card-header">
          <h3 className="card-header__title">{t("roles.list.title")}</h3>
          {createAccountLink && (
            <div className="header__controls">
              <ul className="header-controls">
                <li className="header-controls__item">
                  <a className="header-controls__link" href="/accounts/new">
                    {t("profile.create_account")}
                  </a>
                </li>
              </ul>
            </div>
          )}
        </div>

        <div className="card-content">
          <div className="table-scroll">
            <table className="table table_hover">
              <colgroup>
                {userId === null ? (
                  <>
                    <col style={{ width: "12rem" }} />
                    <col style={{ width: "12rem" }} />
                  </>
                ) : (
                  <col style={{ width: "24rem" }} />
                )}
                <col style={{ width: "auto" }} />
                {canUpdate && <col style={{ width: "4.375rem" }} />}
                <col style={{ width: "5.625rem" }} />
              </colgroup>

              <thead className="thead">
                <tr className="thead__row">
                  {userId === null ? (
                    <>
                      <th className="thead__cell">{t("roles.list.user")}</th>
                      <th className="thead__cell">{t("roles.list.email")}</th>
                    </>
                  ) : (
                    <>
                      <th className="thead__cell">{t("roles.list.entity")}</th>
                    </>
                  )}
                  <th className="thead__cell">{t("roles.list.permissions")}</th>
                  {canUpdate && <th className="thead__cell thead__cell_center">{t("general.edit")}</th>}
                  <th className="thead__cell thead__cell_center">{t("general.remove")}</th>
                </tr>
              </thead>

              <tbody className="tbody">
                {roles.map((role) => {
                  const { badges, group } = permissionBadges(role.permissions)
                  const isLocked = role.entity === null

                  return (
                    <tr className="tbody__row" key={role.id}>
                      {userId === null ? (
                        <>
                          <UserTd user={role.user} showInvitationDetails={setShowInvitationDetailsUser} />
                          <td className="tbody__cell">{role.user.email}</td>
                        </>
                      ) : (
                        <EntityTd entity={role.entity} />
                      )}

                      <td className="tbody__cell">
                        <BadgeContainer>
                          {badges.map((badge) => (
                            <Badge
                              key={badge}
                              $group={group}
                              $clickable={canUpdate}
                              {...(canUpdate && {
                                onClick: () => (isLocked ? setShowLockModal(true) : editRole(role)),
                              })}
                            >
                              {badge}
                            </Badge>
                          ))}
                        </BadgeContainer>
                      </td>

                      {isLocked ? (
                        (canUpdate ? [0, 1] : [0]).map((index) => (
                          <td className="tbody__cell tbody__cell_icon" key={index}>
                            <button className="tbody__cell__icon-button" onClick={() => setShowLockModal(true)}>
                              <i className="fa fa-lock"></i>
                            </button>
                          </td>
                        ))
                      ) : (
                        <>
                          {canUpdate && (
                            <td className="tbody__cell tbody__cell_icon" onClick={() => editRole(role)}>
                              <button className="tbody__cell__icon-button">
                                <i className="fa fa-pencil"></i>
                              </button>
                            </td>
                          )}
                          <td className="tbody__cell tbody__cell_icon" onClick={() => setDeletingUserRole(role)}>
                            <button className="tbody__cell__icon-button">
                              <i className="fa fa-trash"></i>
                            </button>
                          </td>
                        </>
                      )}
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {(() => {
        if (showLockingWarning) {
          return (
            <SelfLockingWarningModal
              onCancel={() => setShowLockingWarning(false)}
              onConfirm={() => {
                removeRole(deletingUserRole!)
                setDeletingUserRole(null)
                setShowLockingWarning(false)
              }}
            />
          )
        } else if (deletingUserRole) {
          return (
            <ConfirmRemoveModal
              userId={userId}
              role={deletingUserRole}
              onCancel={() => setDeletingUserRole(null)}
              onConfirm={(role) => {
                if (role.user.id === selfUserId && role.self_locking_entity_permission) {
                  setShowLockingWarning(true)
                } else {
                  removeRole(role)
                  setDeletingUserRole(null)
                }
              }}
            />
          )
        } else if (showInvitationDetailsUser) {
          return (
            <InvitationSentModal
              user={showInvitationDetailsUser}
              onClose={() => setShowInvitationDetailsUser(null)}
            />
          )
        } else if (showLockModal) {
          return <LockInfoModal onClose={() => setShowLockModal(false)} />
        }
      })()}
    </>
  )
}

export default RoleList
