import type { ChartData, ChartOptions, LegendItem } from "chart.js"
import * as React from "react"
import { Bar, Line } from "react-chartjs-2"
import { useTranslation } from "react-i18next"

import barBorderDashPlugin from "react-app/utils/graphjs/barBorderDashPlugin"
import { currencyFormatter } from "react-app/utils/utils"
import type { DateRange } from "react-app/utils/utils"

import { Container } from "./Styles"
import { GraphType, useAggregatedOrderData } from "./useAggregatedOrderData"

interface SalesGraphProps {
  dateRange: DateRange
  previousDateRange?: DateRange
  type: GraphType
}

const SalesGraph = ({ dateRange, previousDateRange, type }: SalesGraphProps): JSX.Element => {
  const { t } = useTranslation()

  const { start, end } = dateRange
  const { data, isLoading, error } = useAggregatedOrderData(start, end, type)

  const { start: previousStart, end: previousEnd } = previousDateRange ?? dateRange
  const {
    data: previousData,
    isLoading: isPreviousLoading,
    error: previousError,
  } = useAggregatedOrderData(previousStart, previousEnd, type, { enabled: !!previousDateRange })

  if (isLoading || (previousDateRange && isPreviousLoading)) return <div>{t("general.loading")}</div>
  if (error || previousError)
    return <div>{t("general.error", { message: error?.message ?? previousError?.message })}</div>

  const chartData: ChartData<"line"> & ChartData<"bar"> = {
    labels: data.values.map(({ label }) => label),
    datasets: [
      {
        label: dateRange.label,
        data: data.values.map(({ value }) => value),
        borderWidth: 2,
        barPercentage: 1.0,
        borderColor: "#6db24f",
        backgroundColor: "rgba(109, 178, 79, 0.15)",
        fill: true,
      },
    ],
  }

  if (previousDateRange && previousData !== undefined) {
    chartData.datasets.push({
      label: previousDateRange.label,
      data: previousData.values.map(({ value }) => value),
      barPercentage: 1.0,
      backgroundColor: "rgba(109, 178, 79, 0.05)",
      borderColor: "#6db24f",
      ...(type === "average_order"
        ? { boxBorderWidth: 2, boxBorderDash: [5, 5] }
        : { borderWidth: 2, borderDash: [5, 5], fill: true }),
    })
  }

  const chartOptions: ChartOptions<"line"> & ChartOptions<"bar"> = {
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          callback: function (value) {
            switch (type) {
              case "revenue":
              case "average_order":
                return currencyFormatter({ digits: false }).format(value as number)
              case "orders":
                return value
            }
          },
        },
      },
    },
    interaction: {
      intersect: false,
      mode: "index",
    },
    plugins: {
      legend: {
        display: !!previousDateRange,
        labels: {
          generateLabels(chart) {
            return chart.data.datasets.map((dataset, index) => {
              const isPreviousPeriod = index === 1
              return {
                text: dataset.label!,
                strokeStyle: dataset.borderColor as string,
                fillStyle: dataset.backgroundColor as string,
                lineDash: isPreviousPeriod ? [5, 5] : [],
                lineWidth: 2,
              } satisfies LegendItem
            })
          },
        },
      },
      tooltip: {
        displayColors: false,
        callbacks: {
          title: () => "",
          label(context) {
            const isPrevious = context.datasetIndex === 1
            const datasetLabel = data.granularity === "hour" ? `${context.dataset.label} ` : ""
            const periodLabel = (isPrevious ? previousData : data).values[context.dataIndex].label

            let formattedValue = ""
            const value = context.parsed.y

            switch (type) {
              case "revenue":
              case "average_order":
                formattedValue = currencyFormatter({ digits: true }).format(value)
                break
              case "orders":
                formattedValue = t("general.orders", { count: value })
                break
            }

            return `${datasetLabel}${periodLabel}${t("general.colon")} ${formattedValue}`
          },
        },
      },
    },
  }

  return (
    <Container>
      {type === "average_order" ? (
        <Bar data={chartData as ChartData<"bar">} options={chartOptions} plugins={[barBorderDashPlugin]} />
      ) : (
        <Line data={chartData as ChartData<"line">} options={chartOptions} />
      )}
    </Container>
  )
}

export default SalesGraph
