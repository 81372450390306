import * as React from "react"

export interface SumoSelectProps {
  value: string
  options: Array<{ value: string; label: string }>
  onChange: (value: string) => void
}

const SumoSelect: React.FC<SumoSelectProps> = ({ value, options, onChange }) => {
  const selectRef = React.useRef<HTMLSelectElement>(null)

  React.useEffect(() => {
    if (selectRef.current) {
      $(selectRef.current).SumoSelect({ forceCustomRendering: true })
      $(selectRef.current).on("change", (event) => onChange(event.target.value))
    }
  }, [onChange])

  return (
    <select ref={selectRef} defaultValue={value}>
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  )
}

export default SumoSelect
