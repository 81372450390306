export interface BodyData {
  account_id: string
  location_id: string | null
  user_id: string
  timezone: string
  entity_created_at: string
  cutoff_time: string
  currency: string
  locale: string
  isGlobalRoleUser: boolean
  sentry_dsn: string | null
  release: string | null
}

export const bodyData = (): BodyData => {
  const bodyDataset = document.getElementsByTagName("body")[0].dataset
  return {
    account_id: bodyDataset.accountId!,
    location_id: bodyDataset.locationId ?? null,
    user_id: bodyDataset.userId!,
    timezone: bodyDataset.timezone!,
    entity_created_at: bodyDataset.entityCreatedAt!,
    cutoff_time: bodyDataset.cutoffTime!,
    currency: bodyDataset.currency!,
    locale: bodyDataset.locale!,
    isGlobalRoleUser: bodyDataset.isGlobalRoleUser === "true",
    sentry_dsn: bodyDataset.sentryDsn ?? null,
    release: bodyDataset.release ?? null,
  }
}
