import { colors, fontSizes } from "@hubrise/react-components"
import { styled } from "styled-components"

export const Summary = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
`

export const Metrics = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 1rem;
`

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`

export const Title = styled.div`
  color: #6b7280;
`

export const ValueContainer = styled.div`
  display: flex;
  align-items: baseline;
  gap: 0.75rem;
`

export const CurrentValue = styled.div`
  font-size: 18px;
  font-weight: 600;
  color: #111827;
`

export const PreviousPeriod = styled.div`
  margin-top: 0.5rem;
  padding-top: 1rem;
  border-top: 1px solid ${colors.borderLight};
  font-size: ${fontSizes._12};
  color: #6b7280;
`
