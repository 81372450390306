import { styled } from "styled-components"

const ButtonBase = (className: string) => styled.a.attrs({ className })<{ $disabled?: boolean }>`
  ${({ $disabled }) => $disabled === true && "pointer-events: none; opacity: 0.5;"}
`

export const ButtonPrimary = ButtonBase("header-controls__button")

export const ButtonDanger = ButtonBase("header-controls__button header-controls__button_red")

export const ButtonSecondary = ButtonBase("header-controls__button header-controls__button_grey")
