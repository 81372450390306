import { sumoSelect } from "legacy/utils"

const tagDelimiter = '<span class="logs-filter__delimiter"></span>'
let noFilterText: string
let template: Array<{ selector: string; template: string; withCounter: boolean }>
let reloadCallback: () => void

const filterChanged = (): void => {
  const filters: string[] = []
  for (let i = 0; i < template.length; i++) {
    const counter = $(template[i].selector).length
    const prefix = template[i].withCounter ? `${counter} ` : ""
    if (counter > 0) filters.push(prefix + template[i].template)
  }
  $("[data-logs-filter-reset]").toggle(filters.length > 0)
  setFilterValue(filters.join(tagDelimiter) || noFilterText)
  reloadCallback()
}

const init = (
  _reloadCallback: () => void,
  _noFilterText: string,
  resourcesText: string,
  methodsText: string,
  timerangeText: string,
): void => {
  reloadCallback = _reloadCallback
  noFilterText = _noFilterText
  template = [
    {
      selector: "[data-logs-filter-resources] input:checked",
      template: resourcesText,
      withCounter: true,
    },
    {
      selector: "[data-logs-filter-methods] input:checked",
      template: methodsText,
      withCounter: true,
    },
    {
      selector: '[data-logs-filter-times] input:not([value=""])',
      template: timerangeText,
      withCounter: false,
    },
  ]
  setFilterValue(noFilterText)
  $('[data-logs-filter-form] input[type="checkbox"]').on("change", filterChanged)
  $("[data-logs-filter-times] select, [data-logs-filter-times] input.search-box__input").on(
    "change",
    function (this: HTMLInputElement) {
      this.setAttribute("value", this.value)
      filterChanged()
    },
  )
  $("[data-logs-filter-reset]").on("click", resetHandler({ checkboxes: true, times: true }))
  $("[data-logs-filter-reset-time]").on("click", resetHandler({ checkboxes: false, times: true }))
}

const setFilterValue = (text: string): void => {
  $("[data-logs-filter-value]").html(text)
}

const buildCurrentQuery = (): string => {
  return $("[data-logs-filter-form]").serialize()
}

const resetHandler = ({ checkboxes, times }: { checkboxes: boolean; times: boolean }) => {
  return (event: JQuery.ClickEvent): void => {
    event.preventDefault()
    event.stopPropagation()

    if (checkboxes) {
      $("[data-logs-filter-form] input").prop("checked", false)
    }
    if (times) {
      $("[data-logs-filter-times] input.search-box__input").val("").attr("value", "").trigger("change")
      $("[data-logs-filter-times] select").each(function (_, select) {
        sumoSelect(select as HTMLSelectElement).selectItem("")
      })
    }

    filterChanged()
  }
}

const LogsFilter: typeof window.LogsFilter = {
  init,
  buildCurrentQuery,
}

export default LogsFilter
