import type { TFunction } from "i18next"
import { DateTime } from "luxon"
import type { WeekdayNumbers } from "luxon"

import { EntityIds } from "react-app/Permissions/data/Entity"
import i18n from "react-app/utils/i18n"

import PlainDate from "./PlainDate"
import { bodyData } from "./config"
import { Locale } from "./i18n"

export type DateRange = {
  start: PlainDate
  end: PlainDate
  label: string
}

export type DateRangeWithPrevious = {
  current: DateRange
  previous?: DateRange
  compareWithLabel?: string
}

const getRange = (
  start: PlainDate,
  end: PlainDate,
  currentLabel: string,
  previousLabel: string,
  compareWithLabel: string,
  previousStart = start.addDays(-start.daysBetween(end) - 1),
  previousEnd = end.addDays(-start.daysBetween(end) - 1),
): DateRangeWithPrevious => {
  const hasPrevious = entityCreatedDate().daysBetween(previousStart) > 0

  return {
    current: {
      start,
      end,
      label: currentLabel,
    },
    ...(hasPrevious
      ? {
          previous: {
            start: previousStart,
            end: previousEnd,
            label: previousLabel,
          },
        }
      : {}),
    compareWithLabel,
  }
}

export const getCustomRange = (start: PlainDate, end: PlainDate, t: TFunction): DateRangeWithPrevious =>
  getRange(
    start,
    end,
    t("dateRanges.custom.current"),
    t("dateRanges.custom.previous"),
    t("dateRanges.custom.compare_with"),
  )

export const getPredefinedRanges = (now: DateTime, t: TFunction): Array<DateRangeWithPrevious> => {
  const today = PlainDate.fromDateTimeWithCutoff(now)

  return [
    getRange(
      today.addDays(-27),
      today,
      t("dateRanges.lastFourWeeks.current"),
      t("dateRanges.lastFourWeeks.previous"),
      t("dateRanges.lastFourWeeks.compare_with"),
    ),
    getRange(
      today.addDays(-6),
      today,
      t("dateRanges.lastSevenDays.current"),
      t("dateRanges.lastSevenDays.previous"),
      t("dateRanges.lastSevenDays.compare_with"),
    ),
    getRange(
      today,
      today,
      t("dateRanges.today.current"),
      t("dateRanges.today.previous"),
      t("dateRanges.today.compare_with"),
    ),
    getRange(
      today.addDays(-1),
      today.addDays(-1),
      t("dateRanges.yesterday.current"),
      t("dateRanges.yesterday.previous"),
      t("dateRanges.yesterday.compare_with"),
    ),
    getRange(
      today.startOf("week"),
      today.endOf("week"),
      t("dateRanges.currentWeek.current"),
      t("dateRanges.currentWeek.previous"),
      t("dateRanges.currentWeek.compare_with"),
    ),
    getRange(
      today.startOf("month"),
      today.endOf("month"),
      t("dateRanges.currentMonth.current"),
      t("dateRanges.currentMonth.previous"),
      t("dateRanges.currentMonth.compare_with"),
      today.startOf("month").addDays(-1).startOf("month"),
      today.startOf("month").addDays(-1),
    ),
    getRange(
      today.startOf("quarter"),
      today.endOf("quarter"),
      t("dateRanges.currentQuarter.current"),
      t("dateRanges.currentQuarter.previous"),
      t("dateRanges.currentQuarter.compare_with"),
      today.startOf("quarter").addDays(-1).startOf("quarter"),
      today.startOf("quarter").addDays(-1),
    ),
    getRange(
      today.startOf("year"),
      today.endOf("year"),
      t("dateRanges.currentYear.current"),
      t("dateRanges.currentYear.previous"),
      t("dateRanges.currentYear.compare_with"),
      today.startOf("year").addDays(-1).startOf("year"),
      today.startOf("year").addDays(-1),
    ),
    {
      current: {
        start: entityCreatedDate(),
        end: today,
        label: t("dateRanges.fromBeginning.current"),
      },
    },
  ]
}

export const currencyFormatter = ({ digits }: { digits: boolean }) => {
  const { locale, currency } = bodyData()
  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currency,
    minimumFractionDigits: digits ? 2 : 0,
    maximumFractionDigits: 2,
  })
}

export const nowInLocalTime = (): DateTime => {
  const { timezone } = bodyData()
  return DateTime.now().setZone(timezone)
}

export const isWeekdayInRange = (startTime: DateTime, endTime: DateTime, weekday: WeekdayNumbers): boolean => {
  let currentDate = startTime
  while (currentDate <= endTime) {
    if (currentDate.weekday === weekday) return true
    currentDate = currentDate.plus({ days: 1 })
  }
  return false
}

/**
 * Returns the date format pattern for the given locale, e.g. "dd/MM/yyyy" for "en-GB".
 * @param locale
 */
export const getDateFormatPattern = (locale: Locale) =>
  new Intl.DateTimeFormat(locale)
    .formatToParts(new Date())
    .map((part) => {
      switch (part.type) {
        case "day":
          return "dd"
        case "month":
          return "MM"
        case "year":
          return "yyyy"
        default:
          return ""
      }
    })
    .filter(Boolean)
    .join("/")

export const getCurrentLocale = (): Locale => i18n.language as Locale

export const entityCreatedDate = (): PlainDate => {
  const { entity_created_at } = bodyData()
  return PlainDate.fromDateTimeWithCutoff(DateTime.fromISO(entity_created_at))
}

export const entityId = (): string => {
  const { account_id, location_id } = bodyData()
  return location_id ?? account_id
}

export const entityIds = (): EntityIds => {
  const { account_id, location_id } = bodyData()
  return { accountId: account_id, locationId: location_id }
}

export const cutoffHour = () => {
  const { cutoff_time } = bodyData()
  return parseInt(cutoff_time.split(":")[0])
}

export const checkEmailFormat = (email: string): boolean => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
}
