import { styled } from "styled-components"

export const CheckboxContainer = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
`
export const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`

export const StyledCheckbox = styled.div<{ $checked: boolean; $disabled: boolean }>`
  display: inline-block;
  width: 16px;
  height: 16px;
  background: ${({ $checked, $disabled }) => ($disabled ? "#e0e0e0" : $checked ? "#6db24f" : "white")};
  border: 1px solid ${({ $disabled }) => ($disabled ? "#c0c0c0" : "#6db24f")};
  border-radius: 3px;
  transition: all 150ms;
  position: relative;
  cursor: ${({ $disabled }) => ($disabled ? "not-allowed" : "pointer")};
  opacity: ${({ $disabled }) => ($disabled ? 0.7 : 1)};

  ${HiddenCheckbox}:focus + & {
    box-shadow: ${({ $disabled }) => ($disabled ? "none" : "0 0 0 3px rgba(109, 178, 79, 0.5)")};
  }

  &:after {
    content: "";
    display: ${({ $checked }) => ($checked ? "block" : "none")};
    width: 3px;
    height: 8px;
    border: solid white;
    border-width: 0 2px 2px 0;
    position: absolute;
    top: 1px;
    left: 5px;
    transform: rotate(45deg);
    transition: all 150ms;
  }
`

export const CheckboxLabel = styled.span`
  margin-left: 0.5rem;
  user-select: none;
`
