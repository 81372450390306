import { Entity, entityNameAndId } from "react-app/Permissions/data/Entity"
import { Role, RoleIds } from "react-app/Permissions/data/Role"
import { User } from "react-app/Permissions/data/User"
import { bodyData } from "react-app/utils/config"
import { useDebounce } from "react-app/utils/hooks"
import { ApiError, fetchAndRespond, useHubRiseQuery } from "react-app/utils/queries"

export const findUserByEmailQueryKey = (email: string | null) => ["FindUserByEmail", email]

export const useFindUserByEmail = (email: string | null) => {
  const debouncedEmail = useDebounce(email, 500)

  return useHubRiseQuery<User | undefined>({
    enabled: debouncedEmail !== null,
    queryKey: findUserByEmailQueryKey(debouncedEmail),
    queryFn: async () => {
      const { data, success, error, status } = await fetchAndRespond<User | undefined>(
        "GET",
        `/api/users/find_by_email`,
        {
          params: { email: debouncedEmail },
        },
      )
      if (!success) throw new ApiError(error, status)
      return data
    },
  })
}

export const useSearchEntities = (query: string | null) => {
  const debouncedQuery = useDebounce(query, 500)

  return useHubRiseQuery<Array<Entity>>({
    enabled: debouncedQuery !== null,
    queryKey: ["SearchEntities", debouncedQuery],
    queryFn: async () => {
      const { data, success, error, status } = await fetchAndRespond<Array<Entity>>("GET", `/api/entities/search`, {
        params: { query: debouncedQuery },
      })
      if (!success) throw new ApiError(error, status)
      return data
    },
  })
}

export const findRoleQueryKey = (roleIds: RoleIds | null) => {
  if (roleIds) {
    const { userId, accountId, locationId } = roleIds
    return ["FindRole", userId, accountId, locationId]
  } else {
    return ["FindRole"]
  }
}

export const useFindRole = (roleIds: RoleIds | null) => {
  return useHubRiseQuery<Role | undefined>({
    enabled: roleIds !== null,
    queryKey: findRoleQueryKey(roleIds),
    queryFn: async () => {
      const { userId, accountId, locationId } = roleIds ?? {}
      const { data, success, error, status } = await fetchAndRespond<Role | undefined>("GET", `/api/roles/find`, {
        params: { user_id: userId, account_id: accountId, location_id: locationId },
      })
      if (!success) throw new ApiError(error, status)
      return data
    },
  })
}

export const rolesQueryKey = (userId: string | null) => ["Roles", userId]

export const useRoles = (userId: string | null) => {
  return useHubRiseQuery<Array<Role>>({
    queryKey: rolesQueryKey(userId),
    queryFn: async () => {
      const { account_id, location_id } = bodyData()
      const { data, success, error, status } = await fetchAndRespond<Array<Role>>(
        "GET",
        userId !== null ? `/api/users/${userId}/roles` : `/api/entities/${location_id ?? account_id}/roles`,
      )
      if (!success) throw new ApiError(error, status)
      const sortFn = (a: Role, b: Role) =>
        userId !== null
          ? (a.entity ? entityNameAndId(a.entity) : "_").localeCompare(b.entity ? entityNameAndId(b.entity) : "_")
          : a.user.full_name.localeCompare(b.user.full_name)
      return data.sort(sortFn)
    },
  })
}
