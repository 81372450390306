import { UseQueryOptions, useQuery, QueryClient } from "@tanstack/react-query"

const ONE_HOUR = 60 * 60 * 1000

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // gcTime should be greater or equal to maxAge, whose default is 24 hours.
      // See https://tanstack.com/query/latest/docs/framework/react/plugins/persistQueryClient
      gcTime: 24 * ONE_HOUR,
    },
  },
})

export class ApiError extends Error {
  constructor(
    message: string,
    public status: number,
  ) {
    super(message)
    this.name = "ApiError"
  }
}

export function useHubRiseQuery<TData = unknown>(options: Omit<UseQueryOptions<TData, ApiError>, "retry">) {
  return useQuery<TData, ApiError>({
    ...options,
    retry: (failureCount, error) => {
      if (error?.status === 401) return false
      return failureCount < 3
    },
  })
}

export type ApiResponse<T> =
  | { success: true; data: T; status: number; error: null }
  | { success: false; data: null; status: number; error: string }

export const fetchAndRespond = async <T>(
  method: "GET" | "POST" | "PUT" | "DELETE",
  path: string,
  options: { body?: any; params?: object } = {},
): Promise<ApiResponse<T>> => {
  const cleanParams = options.params
    ? Object.fromEntries(Object.entries(options.params).filter(([_, value]) => value != null))
    : {}
  const queryParams = new URLSearchParams(cleanParams)
  const url = new URL(`${path}?${queryParams.toString()}`, window.location.origin)
  const response = await fetch(url.toString(), {
    method,
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(options.body),
  })

  const responseData = (await response.json()) as { error?: string; data?: T }
  if (!response.ok) {
    const error = "error" in responseData ? (responseData.error as string) : `Failed to send request: ${method} ${path}`
    return { success: false, data: null, status: response.status, error }
  }

  return { success: true, data: responseData as T, status: response.status, error: null }
}

export const ErrorCodes = {
  CANNOT_REMOVE_ENTITY_PERMISSION: "[CANNOT_REMOVE_ENTITY_PERMISSION]",
} as const
