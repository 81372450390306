import type { ChartData, ChartOptions } from "chart.js"
import * as React from "react"
import { Doughnut } from "react-chartjs-2"
import { useTranslation } from "react-i18next"

import { useOrderData } from "react-app/utils/queries/OrderData"
import { currencyFormatter } from "react-app/utils/utils"
import type { DateRange } from "react-app/utils/utils"

import {
  Chart,
  ColorSquare,
  Container,
  LabelCell,
  TableContainer,
  pieColors,
  RightAlignedCell,
  Table,
  TableCell,
  TableRow,
} from "./Styles"

type GroupBy = "channel" | "connection_name" | "location"

interface RevenuePieProps {
  dateRange: DateRange
  groupBy: GroupBy
}

const RevenuePie = ({ dateRange, groupBy }: RevenuePieProps): JSX.Element => {
  const { t } = useTranslation()

  const { start, end } = dateRange
  const { data, isLoading, error } = useOrderData(
    start.toDateTimeWithCutoff(),
    end.toDateTimeWithCutoff().plus({ day: 1 }),
    { groupBy },
  )
  if (isLoading || !data) return <div>{t("general.loading")}</div>
  if (error) return <div>{t("general.error", { message: error.message })}</div>

  const totalAmount = data.reduce((sum, item) => sum + parseFloat(item.total_amount.split(" ")[0]), 0)

  const sortedData = [...data].sort(
    (a, b) => parseFloat(b.total_amount.split(" ")[0]) - parseFloat(a.total_amount.split(" ")[0]),
  )

  const sortedLabels = sortedData.map((item) => {
    switch (groupBy) {
      case "channel":
        return item.channel
      case "connection_name":
        return item.connection_name ?? t("dashboard.revenue_pie.connection_name.labels.empty")
      case "location":
        return `${item.location_name} (${item.location_id}) `
    }
  })

  const chartData: ChartData<"doughnut"> = {
    labels: sortedLabels,
    datasets: [
      {
        data: sortedData.map((item) => parseFloat(item.total_amount.split(" ")[0])),
        backgroundColor: pieColors,
      },
    ],
  }

  const chartOptions: ChartOptions<"doughnut"> = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const value = context.parsed
            const total = currencyFormatter({ digits: true }).format(value)
            const percentage = ((value / totalAmount) * 100).toFixed(1)
            return `${total} (${percentage}%)`
          },
        },
      },
    },
    layout: {
      padding: {
        left: 10,
        right: 10,
        top: 0,
        bottom: 0,
      },
    },
    maintainAspectRatio: false,
    cutout: "60%",
  }

  return (
    <Container>
      <Chart>
        <Doughnut data={chartData} options={chartOptions} />
      </Chart>

      <TableContainer>
        <Table>
          <tbody>
            {sortedData.map((item, index) => {
              const value = parseFloat(item.total_amount.split(" ")[0])
              const percentage = ((value / totalAmount) * 100).toFixed(1)
              return (
                <TableRow key={index} data-testid={`row-${index}`}>
                  <TableCell>{index + 1}.</TableCell>
                  <LabelCell>
                    <ColorSquare color={pieColors[index % pieColors.length]} />
                    {sortedLabels[index]}
                  </LabelCell>
                  <RightAlignedCell>{currencyFormatter({ digits: true }).format(value)}</RightAlignedCell>
                  <RightAlignedCell>{percentage}%</RightAlignedCell>
                </TableRow>
              )
            })}
          </tbody>
        </Table>
      </TableContainer>
    </Container>
  )
}

export default RevenuePie
