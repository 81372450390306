import { colors, themeColors } from "@hubrise/react-components"
import { css, styled } from "styled-components"

export const StyledPicker = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
`

export const Wrapper = styled.div<{ $effectOnFocus: boolean }>`
  .react-datepicker__day-name,
  .react-datepicker__day {
    width: 1.5rem;
    padding: 0.25rem;
  }

  .react-datepicker__time-list-item {
    height: 20px !important;
  }

  .react-datepicker__day--in-range,
  .react-datepicker__day--in-selecting-range {
    color: ${colors.textDark} !important;
    background-color: rgba(109, 178, 79, 0.25) !important;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__time-list-item--selected {
    color: white !important;
    background-color: ${themeColors.primary} !important;
  }

  .react-datepicker__day--disabled.react-datepicker__day--keyboard-selected {
    background-color: ${colors.backgroundLight} !important;
  }

  input {
    width: 10rem;
    height: 2rem;

    color: ${colors.textDark};
    background-color: transparent;
    font-size: 13px;
    border: none;
    box-shadow: none;
    cursor: pointer;

    ${(props) =>
      !props.$effectOnFocus &&
      css`
        :focus,
        :focus-visible {
          outline: none;
        }
      `}
  }
`
