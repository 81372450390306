function setHiddenInput(form: HTMLFormElement, name: string, value: string | number | boolean) {
  let input = form.querySelector("[name='" + name + "']")
  if (!input) {
    input = document.createElement("input")
    input.setAttribute("type", "hidden")
    input.setAttribute("name", name)
    form.appendChild(input)
  }
  input.setAttribute("value", String(value))
}

const setBrandSHA = (form: HTMLFormElement) => {
  const selectedBrand = form.querySelector<HTMLSelectElement>("select[name='BRAND']")!.value
  const shaInput = form.querySelector("input[name='SHASIGN']")!
  const brandShaValue = shaInput.getAttribute("data-brand-" + selectedBrand + "-value")!
  shaInput.setAttribute("value", brandShaValue)
}

const set3DSV2Params = (form: HTMLFormElement) => {
  setHiddenInput(form, "3DSV2[browserColorDepth]", screen.colorDepth)
  setHiddenInput(form, "3DSV2[browserJavaEnabled]", navigator.javaEnabled())
  setHiddenInput(form, "3DSV2[browserLanguage]", navigator.language)
  setHiddenInput(form, "3DSV2[browserScreenHeight]", screen.height)
  setHiddenInput(form, "3DSV2[browserScreenWidth]", screen.width)
  setHiddenInput(form, "3DSV2[browserTimeZone]", new Date().getTimezoneOffset())
  setHiddenInput(form, "3DSV2[browserUserAgent]", navigator.userAgent)
}

const IngenicoForm: typeof window.IngenicoForm = {
  setBrandSHA,
  set3DSV2Params,
}

export default IngenicoForm
