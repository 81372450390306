let lastClickedId: string | null = null

const init = (invoiceNumbers: Array<string>): void => {
  $("[data-shift-select]").each(function () {
    const invoiceNumber = $(this).attr("data-shift-select") ?? ""
    if (invoiceNumbers.includes(invoiceNumber)) {
      $(this).click(inputClicked)
    }
  })

  if (lastClickedId === null) {
    lastClickedId = $("[data-shift-select]:first").attr("data-shift-select") ?? null
  }
}

const inputClicked = function (this: HTMLElement, e: JQuery.ClickEvent): void {
  const input = $(this)
  const clickedId = input.attr("data-shift-select") ?? null
  const checked = input.is(":checked")

  if (e.shiftKey && lastClickedId !== null && clickedId !== null) {
    const minId = clickedId < lastClickedId ? clickedId : lastClickedId
    const maxId = clickedId > lastClickedId ? clickedId : lastClickedId

    $("[data-shift-select]").each(function () {
      const id = $(this).attr("data-shift-select") ?? null
      if (id !== null && minId <= id && id <= maxId) {
        $(this).prop("checked", checked)
      }
    })
  }

  lastClickedId = clickedId ?? null
}

const ShiftSelect: typeof window.ShiftSelect = {
  init,
}

export default ShiftSelect
