import Foundation from "foundation-sites"

import { highlightBlock } from "./ConnectionLogs"
import initCopiableCells from "./Page/CopiableCells"

const initReveals = function (root: JQuery<HTMLElement>) {
  root.find("[data-reveal]").each(function (this: HTMLElement) {
    new Foundation.Reveal($(this))
  })
}

const initDropdowns = function (root: JQuery<HTMLElement>) {
  root.find("[data-dropdown]").each(function () {
    new Foundation.Dropdown($(this), {
      hover: false,
      closeOnClick: true,
    })
  })
}

const initSumoSelects = function (root: JQuery<HTMLElement>, searchText: string) {
  root.find("[data-sumo]").SumoSelect({ forceCustomRendering: true })
  root.find("[data-sumo-up]").SumoSelect({
    forceCustomRendering: true,
    up: true,
  })
  root.find("[data-sumo-search]").SumoSelect({
    forceCustomRendering: true,
    search: true,
    searchText,
    floatWidth: undefined,
  })
}

const initDatepickers = function (root: JQuery<HTMLElement>) {
  root.find('[data-toggle="datepicker"]').datepicker({
    autoHide: true,
    format: "dd/mm/yyyy",
  })
  root.find('[data-toggle="modal-datepicker"]').datepicker({
    autoHide: true,
    format: "dd/mm/yyyy",
    zIndex: 1010,
  }) // foundation/reveal is currently on 1005 zIndex
}

const initHighlights = function (_root: JQuery<HTMLElement>) {
  $("[data-code]").each(function (_, block) {
    setTimeout(() => highlightBlock(block), 0)
  })
}

const initFlashNotice = function (root: JQuery<HTMLElement>) {
  setTimeout(function () {
    root.find("[data-flash-notice]").fadeOut()
  }, 3000)
}

const initMobileMenu = function () {
  // Off canvas menu
  if ($("#off-canvas").length) {
    new Foundation.OffCanvas($("#off-canvas"))

    // Leftbar selectors
    new Foundation.AccordionMenu($("#leftbar-selectors"), {
      multiOpen: false,
      slideSpeed: 50,
    })
  }

  $(window).on("down.zf.accordionMenu", function (e) {
    $(e.target)
      .find(".leftbar-selectors__menu")
      .filter(":visible")
      .each(function () {
        $(this).siblings(".leftbar-selectors__link").addClass("leftbar-selectors__link_open")
      })
  })

  $(window).on("up.zf.accordionMenu", function (e) {
    $(e.target)
      .find(".leftbar-selectors__menu")
      .filter(":hidden")
      .each(function () {
        $(this).siblings(".leftbar-selectors__link_open").removeClass("leftbar-selectors__link_open")
      })
  })
}

const floatingTopbar = function () {
  $(window).on("scroll", function () {
    const topbar = $(".topbar")
    if (this.scrollY > 0) {
      topbar.addClass("topbar_fixed")
    } else {
      topbar.removeClass("topbar_fixed")
    }
  })
}

const init = function (root: JQuery<HTMLElement> = $(document.body)) {
  initReveals(root)
  initDropdowns(root)
  initSumoSelects(root, "...")
  initDatepickers(root)
  initHighlights(root)
  initFlashNotice(root)

  initMobileMenu()
  floatingTopbar()
  if (window.pageConfig.isGlobalRoleUser) initCopiableCells()
}

const initTabs = function (element: JQuery<HTMLElement>) {
  new Foundation.Tabs(element, {
    linkClass: "tabs-header__tab",
    linkActiveClass: "tabs-header__tab_active",
    panelClass: "tabs-content__panel",
    panelActiveClass: "tabs-content__panel_active",
  })
}

const initOrbit = function (element: JQuery<HTMLElement>) {
  new Foundation.Orbit(element, {
    containerClass: "orbit__container",
    slideClass: "orbit__slide",
    boxOfBullets: "orbit__bullets",
    nextClass: "orbit__next",
    prevClass: "orbit__prev",
    useMUI: false,
  })
}

const Page: typeof window.Page = {
  init,
  initTabs,
  initOrbit,
}

export default Page
