import { DateTime } from "luxon"
import type { DateTimeUnit } from "luxon"

import { bodyData } from "react-app/utils/config"
import { cutoffHour } from "react-app/utils/utils"

type DateString = `${number}${number}${number}${number}-${number}${number}-${number}${number}`

class PlainDate {
  private constructor(private readonly date: DateString) {}

  static fromString(dateStr: string): PlainDate {
    // Validate the format
    if (!/^\d{4}-\d{2}-\d{2}$/.test(dateStr)) {
      throw new Error("Invalid date format. Expected YYYY-MM-DD, got: " + dateStr)
    }

    // Validate the date
    const parsed = DateTime.fromISO(dateStr)
    if (!parsed.isValid) {
      throw new Error(`Invalid date: ${parsed.invalidExplanation}`)
    }

    return new PlainDate(dateStr as DateString)
  }

  addDays(days: number): PlainDate {
    const dt = this.toDateTime()
    return PlainDate.fromDateTime(dt.plus({ days }))
  }

  daysBetween(other: PlainDate): number {
    const dt1 = this.toDateTime()
    const dt2 = other.toDateTime()
    return Math.floor(dt2.diff(dt1, "days").days)
  }

  startOf(period: DateTimeUnit): PlainDate {
    const dt = this.toDateTime()
    return PlainDate.fromDateTime(dt.startOf(period))
  }

  endOf(period: DateTimeUnit): PlainDate {
    const dt = this.toDateTime()
    return PlainDate.fromDateTime(dt.endOf(period))
  }

  static fromDateTimeWithCutoff(dt: DateTime): PlainDate {
    return PlainDate.fromDateTime(dt.minus({ hours: cutoffHour() }))
  }

  toDateTimeWithCutoff(): DateTime {
    return this.toDateTime().plus({ hours: cutoffHour() })
  }

  static fromJSDateBrowserTZ(date: Date): PlainDate {
    const dt = DateTime.fromJSDate(date)
    return PlainDate.fromString(dt.toISODate()!)
  }

  toJSDateBrowserTZ(): Date {
    return DateTime.fromISO(this.date).toJSDate()
  }

  private static fromDateTime(dt: DateTime): PlainDate {
    return new PlainDate(dt.toISODate({}) as DateString)
  }

  private toDateTime(): DateTime {
    const { timezone } = bodyData()
    return DateTime.fromISO(this.date, { zone: timezone })
  }

  toString(): string {
    return this.date
  }

  // Custom inspection for browser console.log
  [Symbol.for("Symbol.toPrimitive")](_hint: string): string | number {
    return this.date
  }
}

export default PlainDate
