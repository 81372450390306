import * as Sentry from "@sentry/browser"

import { BodyData } from "react-app/utils/config"

export const initSentry = ({ sentry_dsn, release }: Pick<BodyData, "sentry_dsn" | "release">) => {
  if (sentry_dsn !== null) {
    Sentry.init({
      dsn: sentry_dsn,
      release: release ?? undefined,
      beforeSend(event: Sentry.ErrorEvent) {
        const exception = event.exception?.values?.[0]
        if (exception) {
          exception.type = "[JS] " + exception.type
        }
        return event
      },
    })

    Sentry.getCurrentScope().setTag("js", true)
  }
}
