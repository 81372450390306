import { toastSuccess } from "@hubrise/react-components"
import { DateTime } from "luxon"
import * as React from "react"
import { Trans, useTranslation } from "react-i18next"
import { styled } from "styled-components"

import Checkbox from "react-app/Dashboard/SalesSummary/Checkbox"
import { User } from "react-app/Permissions/data/User"
import { useResendInvitation } from "react-app/Permissions/data/mutations"
import { useFindUserByEmail } from "react-app/Permissions/data/queries"
import ManagerModal from "react-app/shared/ManagerModal"
import {
  ModalContainer,
  ModalContent,
  ModalParagraph,
  ModalFooterButtons,
  ModalTitle,
} from "react-app/shared/ManagerModal/commonStyles"
import { ButtonPrimary, ButtonSecondary } from "react-app/shared/commonStyles"
import { bodyData } from "react-app/utils/config"
import i18n from "react-app/utils/i18n"

interface InvitationSentModalProps {
  user: User
  onClose: () => void
}

const Email = styled.span`
  font-weight: 600;
  word-break: break-all;
`

const InvitationSentModal = ({ user, onClose }: InvitationSentModalProps): JSX.Element => {
  const { t } = useTranslation()
  const { timezone, account_id } = bodyData()

  const [sendCopy, setSendCopy] = React.useState(false)
  const { mutateAsync: resendInvitation } = useResendInvitation(user)

  const InvitationPending = user.invitation_pending!
  const { data: by } = useFindUserByEmail(InvitationPending.by_email)

  if (!by) return <></>

  const formattedInvitationDate = DateTime.fromISO(InvitationPending.sent_at)
    .setZone(timezone)
    .setLocale(i18n.language)
    .toFormat(t("general.format.date_time_long"))

  return (
    <ManagerModal onClose={onClose}>
      <ModalContainer>
        <ModalTitle>{t("roles.invitation_details.title")}</ModalTitle>

        <ModalContent>
          <ModalParagraph>
            <Trans
              i18nKey="roles.invitation_details.message_1"
              values={{
                email: user.email,
                date: formattedInvitationDate,
                inviter: by.full_name,
              }}
              components={{
                Email: <Email />,
              }}
            />
          </ModalParagraph>
          <ModalParagraph>{t("roles.invitation_details.message_2")}</ModalParagraph>
          <ModalParagraph>
            <Checkbox checked={sendCopy} onChange={() => setSendCopy((v) => !v)} label={t("roles.invite.send_copy")} />
          </ModalParagraph>
        </ModalContent>

        <ModalFooterButtons>
          <ButtonSecondary onClick={onClose}>{t("general.close")}</ButtonSecondary>
          <ButtonPrimary
            onClick={() =>
              void resendInvitation({ sendCopy, accountId: account_id }).then(() => {
                toastSuccess(t("roles.invitation_details.invitation_resent", { email: user.email }))
                onClose()
              })
            }
          >
            {t("roles.invitation_details.resend_invitation")}
          </ButtonPrimary>
        </ModalFooterButtons>
      </ModalContainer>
    </ManagerModal>
  )
}

export default InvitationSentModal
