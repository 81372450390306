import { locales } from "@hubrise/react-components"
import { enGB as dateFns_enGB } from "date-fns/locale/en-GB"
import { fr as dateFns_frFR } from "date-fns/locale/fr"
import i18n, { use } from "i18next"
import { registerLocale as ReactDatePicker_registerLocale } from "react-datepicker"
import { initReactI18next } from "react-i18next"

import translationEn from "./locales/en.json"
import translationFr from "./locales/fr.json"

ReactDatePicker_registerLocale("en-GB", dateFns_enGB)
ReactDatePicker_registerLocale("fr-FR", dateFns_frFR)

export type Locale = "en-GB" | "fr-FR"

export const resources = {
  en: { translation: { ...locales.en, ...translationEn } },
  fr: { translation: { ...locales.fr, ...translationFr } },
} as const

void use(initReactI18next).init({
  resources,
  lng: "en-GB",
  fallbackLng: "en-GB",
  returnNull: false,
  interpolation: {
    escapeValue: false, // Not needed for React as it escapes by default
  },
})

export default i18n
