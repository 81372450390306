import { boxShadows, breakpoints, mixin, sizes, zIndexValues } from "@hubrise/react-components"
import { styled } from "styled-components"
import type { RuleSet } from "styled-components"

export const ScrollOverlay = styled.div`
  z-index: ${zIndexValues.modal};
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  ${mixin.scrollableY as RuleSet}; // Temporary fix until we migrate react-components to styled-components v6
`

export const ClickableOverlay = styled.div`
  min-height: 100%;
  background: rgba(100, 100, 100, 0.5);
  display: flex;
  justify-content: center;
  align-items: start;
`

export const StyledModal = styled.div<{ $maxWidth: string }>`
  width: 100%;
  max-width: ${(props) => props.$maxWidth};
  background-color: white;
  border-radius: ${sizes.borderRadius};
  box-shadow: ${boxShadows.large};
  margin: 0 auto;

  @media (min-width: ${breakpoints.tablet}) {
    margin-top: 6rem;
  }
`
