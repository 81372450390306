import * as React from "react"
import { useTranslation } from "react-i18next"

import {
  ModalContainer,
  ModalContent,
  ModalParagraph,
  ModalFooterButtons,
  ModalTitleDanger,
} from "react-app/shared/ManagerModal/commonStyles"
import { ButtonDanger, ButtonSecondary } from "react-app/shared/commonStyles"

interface SelfLockingWarningProps {
  message: string
  onConfirm: () => void
  onCancel: () => void
}

const SelfLockingWarning = ({ message, onConfirm, onCancel }: SelfLockingWarningProps): JSX.Element => {
  const { t } = useTranslation()

  return (
    <ModalContainer>
      <ModalTitleDanger>{t("roles.self_locking_warning.title")}</ModalTitleDanger>
      <ModalContent>
        <ModalParagraph>{message}</ModalParagraph>
        <ModalParagraph>{t("roles.self_locking_warning.message_2")}</ModalParagraph>
      </ModalContent>
      <ModalFooterButtons>
        <ButtonSecondary onClick={onCancel}>{t("general.cancel")}</ButtonSecondary>
        <ButtonDanger onClick={onConfirm}>{t("roles.self_locking_warning.confirm")}</ButtonDanger>
      </ModalFooterButtons>
    </ModalContainer>
  )
}

export default SelfLockingWarning
