import { sumoSelect } from "legacy/utils"

const refreshCurrency = (url: string, countrySelect: HTMLSelectElement, currencySelector: string) => {
  const countryCode = countrySelect.value
  const currencySelect: JQuery<HTMLSelectElement> = $(currencySelector)

  $.ajax({
    type: "GET",
    url,
    data: { country: countryCode },
    success: function (currencyCode: string) {
      $(currencySelect).val(currencyCode)
      sumoSelect(currencySelect[0]).reload()
    },
  })
}

const AccountForm: typeof window.AccountForm = {
  refreshCurrency,
}

export default AccountForm
