import { useQuery, UseQueryResult } from "@tanstack/react-query"
import { DateTime } from "luxon"

import { buildUrlWithTimes } from "react-app/utils/queries/common"
import { entityId } from "react-app/utils/utils"

export const DAYS = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"] as const
export const HOURS = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23] as const

export type Day = (typeof DAYS)[number]
export type Hour = (typeof HOURS)[number]

export type OrderData = Array<{
  date: string
  weekday?: Day
  hour?: Hour
  channel?: string
  connection_name?: string
  location_id?: string
  location_name?: string
  order_count: number
  total_amount: string
}>

interface OrderDataOptions {
  enabled?: boolean
  groupBy: "date" | "date-hour" | "weekday-hour" | "channel" | "connection_name" | "location"
}

const ALL_STATUSES = [
  "new",
  "received",
  "accepted",
  "in_preparation",
  "awaiting_shipment",
  "awaiting_collection",
  "in_delivery",
  "completed",
  "rejected",
  "cancelled",
  "delivery_failed",
] as const

const EXCLUDED_STATUSES = new Set(["cancelled", "rejected", "delivery_failed"])

const VALID_STATUSES = ALL_STATUSES.filter((status) => !EXCLUDED_STATUSES.has(status))

export const useOrderData = (
  startTime: DateTime,
  endTime: DateTime,
  { groupBy, enabled }: OrderDataOptions,
): UseQueryResult<OrderData> => {
  return useQuery<OrderData>({
    enabled,
    queryKey: ["OrderData", startTime, endTime, groupBy],
    queryFn: () => fetchOrderData(startTime, endTime, groupBy),
  })
}

const fetchOrderData = async (
  startTime: DateTime,
  endTime: DateTime,
  groupBy: OrderDataOptions["groupBy"],
): Promise<OrderData> => {
  const params = new URLSearchParams()

  if (groupBy === "date-hour") {
    params.append("group_by[]", "date")
    params.append("group_by[]", "hour")
  } else if (groupBy === "weekday-hour") {
    params.append("group_by[]", "weekday")
    params.append("group_by[]", "hour")
  } else {
    params.append("group_by[]", groupBy)
  }

  VALID_STATUSES.forEach((status) => params.append("statuses[]", status))

  const url = buildUrlWithTimes(`/api/entities/${entityId()}/analytics/orders`, startTime, endTime, params)

  const response = await fetch(url.toString())
  if (!response.ok) {
    throw new Error(`Failed to fetch: ${response.statusText}`)
  }
  return response.json() as Promise<OrderData>
}
