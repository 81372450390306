import { modalWidth, toastSuccess } from "@hubrise/react-components"
import * as React from "react"
import { Trans, useTranslation } from "react-i18next"

import Checkbox from "react-app/Dashboard/SalesSummary/Checkbox"
import { EntityIds } from "react-app/Permissions/data/Entity"
import { Role } from "react-app/Permissions/data/Role"
import { useSendInvitation } from "react-app/Permissions/data/mutations"
import ManagerModal from "react-app/shared/ManagerModal"
import {
  ModalContainer,
  ModalContent,
  ModalParagraph,
  ModalFooterButtons,
  ModalTitle,
} from "react-app/shared/ManagerModal/commonStyles"
import { ButtonPrimary, ButtonSecondary } from "react-app/shared/commonStyles"

import { Email } from "./Styles"

interface InvitationModalProps {
  email: string
  entityIds: EntityIds
  onCancel: () => void
  onSend: (role: Role) => void
}

const InvitationModal = ({ email, entityIds, onCancel, onSend }: InvitationModalProps): JSX.Element => {
  const { t } = useTranslation()

  const [sendCopy, setSendCopy] = React.useState(false)
  const { mutateAsync: sendInvitation } = useSendInvitation(entityIds)

  return (
    <ManagerModal onClose={onCancel} maxWidth={modalWidth.medium}>
      <ModalContainer>
        <ModalTitle>{t("roles.invite.title")}</ModalTitle>

        <ModalContent>
          <ModalParagraph>
            <Trans i18nKey="roles.invite.message" components={{ Email: <Email /> }} values={{ email }} />
          </ModalParagraph>

          <ModalParagraph>
            <Checkbox checked={sendCopy} onChange={() => setSendCopy((v) => !v)} label={t("roles.invite.send_copy")} />
          </ModalParagraph>
        </ModalContent>

        <ModalFooterButtons>
          <ButtonSecondary onClick={onCancel}>{t("general.cancel")}</ButtonSecondary>
          <ButtonPrimary
            $disabled={!email}
            onClick={() =>
              void sendInvitation({ email, sendCopy }).then((role) => {
                toastSuccess(t("roles.invite.invitation_sent", { email }))
                onSend(role)
              })
            }
          >
            {t("roles.invite.invite_user")}
          </ButtonPrimary>
        </ModalFooterButtons>
      </ModalContainer>
    </ManagerModal>
  )
}

export default InvitationModal
