import * as React from "react"
import { useTranslation } from "react-i18next"

import { ButtonPrimary } from "react-app/shared/commonStyles"
import { useMinDisplay } from "react-app/utils/hooks"

import { entityNameAndId } from "../data/Entity"
import { Role, RoleIds } from "../data/Role"
import { useFindRole, useSearchEntities } from "../data/queries"

import { Container, StyledIcon, Input, InputWrapper, Info, PlusIcon, SearchBox, Spacer } from "./Styles"

type Status = "searching" | "not_found" | "empty" | "found"

interface AddEntityToUserProps {
  userId: string
  setEditingRole: (role: Role) => void
  query: string | null
  setQuery: (query: string | null) => void
}

const AddEntityToUser = ({ userId, setEditingRole, query, setQuery }: AddEntityToUserProps): JSX.Element => {
  const { t } = useTranslation()

  const { data: entities, isLoading } = useSearchEntities(query)
  const showLoading = useMinDisplay(isLoading, 250)

  const selectedEntity = entities !== undefined && entities.length ? entities[0] : null

  const editingRoleIds: RoleIds | null = selectedEntity
    ? {
        userId,
        accountId: selectedEntity.account.id,
        locationId: selectedEntity.location?.id ?? null,
      }
    : null
  const { data: editingRole, error: editingRoleError } = useFindRole(editingRoleIds)

  if (editingRoleError) {
    return <div>{editingRoleError.message}</div>
  }

  const status = ((): Status => {
    if (query === null) return "empty"
    if (showLoading) return "searching"
    if (entities !== undefined && entities.length) return "found"
    return "not_found"
  })()

  const statusIcon = {
    searching: <i className="fa fa-spinner fa-spin"></i>,
    not_found: <i className="fa fa-times"></i>,
    empty: <i className="fa fa-search"></i>,
    found: <i className="fa fa-check"></i>,
  }[status]

  const statusInfo = {
    searching: () => t("roles.add_role.status.searching"),
    not_found: () => t("roles.add_role.status.entity_not_found"),
    empty: () => null,
    found: () => entityNameAndId(entities![0]),
  }[status]()

  return (
    <Container className="card">
      <SearchBox>
        <InputWrapper>
          <Input
            placeholder={t("roles.add_role.query_placeholder")}
            value={query ?? ""}
            onChange={(e) => setQuery(e.target.value !== "" ? e.target.value : null)}
          />
        </InputWrapper>
        <StyledIcon>{statusIcon}</StyledIcon>
      </SearchBox>

      {statusInfo !== null && <Info $highlight={status === "found"}>{statusInfo}</Info>}

      <Spacer />

      {selectedEntity && editingRole ? (
        <ButtonPrimary onClick={() => setEditingRole(editingRole)}>
          {t("roles.add_role.edit_permissions")}
        </ButtonPrimary>
      ) : (
        <PlusIcon>
          <i className="fa fa-plus"></i>
        </PlusIcon>
      )}
    </Container>
  )
}

export default AddEntityToUser
