import { styled } from "styled-components"

export const pieColors = [
  "#6cb14f",
  "#f7c143",
  "#f57f17",
  "#4f90b2",
  "#b24f6d",
  "#b2904f",
  "#904fb2",
  "#584fb1",
  "#b2564f",
  "#d95147",
  "#888888",
]

export const Container = styled.div`
  height: 100%;
  margin: 0 -0.5rem; // More horizontal space for the table
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
`

export const Chart = styled.div`
  position: relative; // Required for responsive charts - see https://www.chartjs.org/docs/latest/configuration/responsive.html
  flex: 1;
  min-height: 0;
  max-width: 12rem;
  max-height: 12rem;
`

export const TableContainer = styled.div`
  margin-top: 0.75rem;
  max-height: 12rem;
  max-width: 100%;
  overflow-y: auto;
  scrollbar-width: thin;
`

export const Table = styled.table`
  width: 100%;
  border: none;
`

export const TableRow = styled.tr``

export const TableCell = styled.td`
  padding: 0.25rem;
`

export const LabelCell = styled(TableCell)`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`

export const ColorSquare = styled.div<{ color: string }>`
  width: 1rem;
  height: 1rem;
  background-color: ${(props) => props.color};
`

export const RightAlignedCell = styled(TableCell)`
  text-align: right;
`
