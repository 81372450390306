import * as React from "react"

interface TabsProps {
  activeIndex: number
  setActiveIndex: (index: number) => void
  children: Array<{
    label: string
    content: React.ReactNode
  }>
}

const Tabs = ({ activeIndex, setActiveIndex, children: tabs }: TabsProps): JSX.Element => {
  // Use common CSS for style consistency with the Rails rendered views.
  return (
    <section className="tabs">
      <header className="tabs__header tabs-header tabs-header_expanded">
        <ul className="tabs-header__list">
          {tabs.map((tab, index) => (
            <li key={index} className={"tabs-header__tab" + (index === activeIndex ? " tabs-header__tab_active" : "")}>
              <a className="tabs-header__tab-link" onClick={(_e) => setActiveIndex(index)}>
                {tab.label}
              </a>
            </li>
          ))}
        </ul>
      </header>

      <div className="tabs__content tabs-content">
        {tabs.map((tab, index) => (
          <div
            key={index}
            className={"tabs-content__panel" + (index === activeIndex ? " tabs-content__panel_active" : "")}
            style={{ padding: "1rem" }}
          >
            {tab.content}
          </div>
        ))}
      </div>
    </section>
  )
}

export default Tabs
