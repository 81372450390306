import { colors, themeColors } from "@hubrise/react-components"
import { styled } from "styled-components"

export const StyledTopProducts = styled.div`
  display: flex;
  flex-direction: column;
`

export const MultiColumn = styled.div`
  column-count: 3;
  column-width: 15rem;
  column-gap: 3rem;
`

export const Item = styled.div`
  padding: 0.5rem 0;
  display: flex;
  align-items: flex-start;
  border-bottom: 1px solid #eee;
  gap: 1rem;
`

export const Count = styled.div``

export const NameAndQuantity = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 1rem;
`

export const Name = styled.div``

export const Quantity = styled.div`
  font-weight: bold;
`

export const Footer = styled.div`
  margin: 0.5rem 0 -1rem 0;
  padding: 0.35rem 0;
  display: flex;
  border-top: 1px solid #eee;
`

export const FooterLink = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  color: ${themeColors.primary};
  padding: 0.5rem 1rem;
  cursor: pointer;

  &:hover {
    color: ${colors.textMedium};
  }
`
