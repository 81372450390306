import RailsUjs from "@rails/ujs"
import { Chart } from "chart.js"
import $ from "jquery"
import "what-input"
import "sumoselect"
import "@chenfengyuan/datepicker"

import AccountForm from "legacy/AccountForm"
import AppSlides from "legacy/AppSlides"
import Autoscroll from "legacy/Autoscroll"
import CardFoldable from "legacy/CardFoldable"
import ConnectionLogs from "legacy/ConnectionLogs"
import ImageInput from "legacy/ImageInput"
import IngenicoForm from "legacy/IngenicoForm"
import InvoiceClipboard from "legacy/InvoiceClipboard"
import LocationForm from "legacy/LocationForm"
import LogsFilter from "legacy/LogsFilter"
import Modal from "legacy/Modal"
import OAuth from "legacy/OAuth"
import Page from "legacy/Page"
import ShiftSelect from "legacy/ShiftSelect"
import UserRolesForm from "legacy/UserRolesForm"
import { initSentry } from "legacy/sentry"
import ReactApp from "react-app"
import { bodyData } from "react-app/utils/config"

import "./vue/QuoteLines.js"

window.$ = $

window.AccountForm = AccountForm
window.AppSlides = AppSlides
window.Autoscroll = Autoscroll
window.CardFoldable = CardFoldable
window.Chart = Chart
window.ConnectionLogs = ConnectionLogs
window.ImageInput = ImageInput
window.IngenicoForm = IngenicoForm
window.InvoiceClipboard = InvoiceClipboard
window.LocationForm = LocationForm
window.LogsFilter = LogsFilter
window.Modal = Modal
window.OAuth = OAuth
window.Page = Page
window.ReactApp = ReactApp
window.ShiftSelect = ShiftSelect
window.UserRolesForm = UserRolesForm

RailsUjs.start()

$(function () {
  const { locale, isGlobalRoleUser, sentry_dsn, release } = bodyData()

  initSentry({ sentry_dsn, release })
  window.pageConfig = { locale, isGlobalRoleUser }
  Page.init()
})
