import * as React from "react"

import { CheckboxContainer, CheckboxLabel, HiddenCheckbox, StyledCheckbox } from "./Styles"

interface CheckboxProps {
  checked: boolean
  disabled?: boolean
  onChange: () => void
  label: string
}

const Checkbox = ({ checked, disabled, onChange, label }: CheckboxProps): JSX.Element => (
  <CheckboxContainer>
    <HiddenCheckbox checked={checked} onChange={onChange} />
    <StyledCheckbox $checked={checked} $disabled={disabled === true} />
    <CheckboxLabel>{label}</CheckboxLabel>
  </CheckboxContainer>
)

export default Checkbox
