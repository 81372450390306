import * as React from "react"
import ReactDOM from "react-dom/client"

import App from "./App"
import { AppProps } from "./types"

const ReactApp: typeof window.ReactApp = {
  render: (root: HTMLElement, props: AppProps) => {
    const reactDomRoot = ReactDOM.createRoot(root)
    reactDomRoot.render(<App {...props} />)
  },
}

export default ReactApp
