import { toastError } from "@hubrise/react-components"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { useTranslation } from "react-i18next"

import { Role } from "react-app/Permissions/data/Role"
import { User } from "react-app/Permissions/data/User"
import { findRoleQueryKey, findUserByEmailQueryKey, rolesQueryKey } from "react-app/Permissions/data/queries"
import { ApiError, ErrorCodes, fetchAndRespond } from "react-app/utils/queries"

import { EntityIds } from "./Entity"

const useToastError = () => {
  const { t } = useTranslation()

  return (error: ApiError) => {
    if (error.message === ErrorCodes.CANNOT_REMOVE_ENTITY_PERMISSION) {
      toastError(
        t("roles.errors.cannot_remove_entity_permission.title"),
        t("roles.errors.cannot_remove_entity_permission.message"),
      )
    } else {
      toastError(t("general.error_without_message"), error.message)
    }
  }
}

export const useCreateRole = (userId: string | null) => {
  const queryClient = useQueryClient()
  const toastOnError = useToastError()

  return useMutation<void, ApiError, Role>({
    mutationFn: async (role) => {
      const { success, error, status } = await fetchAndRespond<void>("POST", "/api/roles", { body: role })
      if (!success) throw new ApiError(error, status)
    },
    onSuccess: async (_, role) => {
      const { account, location } = role.entity ?? {}
      queryClient.removeQueries({
        queryKey: findRoleQueryKey({
          userId: role.user.id,
          accountId: account?.id ?? null,
          locationId: location?.id ?? null,
        }),
      })
      await queryClient.invalidateQueries({ queryKey: rolesQueryKey(userId) })
    },
    onError: (error) => toastOnError(error),
  })
}

export const useRemoveRole = (userId: string | null) => {
  const queryClient = useQueryClient()
  const toastOnError = useToastError()

  return useMutation<void, ApiError, string>({
    mutationFn: async (roleId) => {
      const { success, error, status } = await fetchAndRespond<void>("DELETE", `/api/roles/${roleId}`)
      if (!success) throw new ApiError(error, status)
    },
    onSuccess: () => queryClient.invalidateQueries({ queryKey: rolesQueryKey(userId) }),
    onError: (error) => toastOnError(error),
  })
}

export const useSendInvitation = (entityIds: EntityIds) => {
  const toastOnError = useToastError()

  return useMutation<Role, ApiError, { email: string; sendCopy: boolean }>({
    mutationFn: async ({ email, sendCopy }) => {
      const {
        data: user,
        success: userSuccess,
        error: userError,
        status: userStatus,
      } = await fetchAndRespond<User>("POST", `/api/users/invite`, {
        params: { email, send_copy: sendCopy, account_id: entityIds.accountId },
      })
      if (!userSuccess) throw new ApiError(userError, userStatus)

      const {
        data: role,
        success: roleSuccess,
        error: roleError,
        status: roleStatus,
      } = await fetchAndRespond<Role | undefined>("GET", `/api/roles/find`, {
        params: { user_id: user.id, account_id: entityIds.accountId, location_id: entityIds.locationId },
      })
      if (!roleSuccess) throw new ApiError(roleError, roleStatus)
      return role!
    },
    onError: (error) => toastOnError(error),
  })
}

export const useResendInvitation = (user: User) => {
  const queryClient = useQueryClient()
  const toastOnError = useToastError()

  return useMutation<void, ApiError, { sendCopy: boolean; accountId: string }>({
    mutationFn: async ({ sendCopy, accountId }) => {
      const { success, error, status } = await fetchAndRespond<void>(
        "POST",
        `/api/users/${user.id}/resend_invitation`,
        { params: { send_copy: sendCopy, account_id: accountId } },
      )
      if (!success) throw new ApiError(error, status)
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: findUserByEmailQueryKey(user.email) })
      await queryClient.invalidateQueries({ queryKey: rolesQueryKey(null) })
    },
    onError: (error) => toastOnError(error),
  })
}
