import PlainDate from "./PlainDate"

const STORAGE_KEY = "dashboardState"

interface StateStorage {
  selectedRangeIndex: number | "custom"
  activeTabIndex: number
  startDate: string
  endDate: string
  compareWithPrevious: boolean
  topListViewAll: boolean
}

interface State {
  selectedRangeIndex: number | "custom"
  activeTabIndex: number
  startDate: PlainDate
  endDate: PlainDate
  compareWithPrevious: boolean
  topListViewAll: boolean
}

const DEFAULT_STATE: State = {
  selectedRangeIndex: 0,
  activeTabIndex: 0,
  startDate: PlainDate.fromJSDateBrowserTZ(new Date()),
  endDate: PlainDate.fromJSDateBrowserTZ(new Date()),
  compareWithPrevious: false,
  topListViewAll: false,
}

export const getDashboardLocalStorage = (): State => {
  const jsonState = localStorage.getItem(STORAGE_KEY) ?? "{}"
  const storedState = JSON.parse(jsonState) as Record<string, unknown>

  const readPlainDate = (value: unknown): PlainDate => {
    if (typeof value !== "string") throw new Error()
    return PlainDate.fromString(value)
  }

  const readNumber = (value: unknown): number => {
    if (typeof value !== "number") throw new Error()
    return value
  }

  const readBoolean = (value: unknown): boolean => {
    if (typeof value !== "boolean") throw new Error()
    return value
  }

  try {
    const selectedRangeIndex =
      storedState.selectedRangeIndex === "custom" || typeof storedState.selectedRangeIndex === "number"
        ? storedState.selectedRangeIndex
        : DEFAULT_STATE.selectedRangeIndex

    return {
      selectedRangeIndex,
      activeTabIndex: readNumber(storedState.activeTabIndex),
      startDate: readPlainDate(storedState.startDate),
      endDate: readPlainDate(storedState.endDate),
      compareWithPrevious: readBoolean(storedState.compareWithPrevious),
      topListViewAll: readBoolean(storedState.topListViewAll),
    }
  } catch {
    setDashboardLocalStorage(DEFAULT_STATE, { replace: true })
    return { ...DEFAULT_STATE }
  }
}

export const setDashboardLocalStorage = (
  state: Partial<State>,
  { replace = false }: { replace?: boolean } = {},
): void => {
  const newState = replace ? (state as State) : { ...getDashboardLocalStorage(), ...state }

  const storedState: StateStorage = {
    selectedRangeIndex: newState.selectedRangeIndex,
    activeTabIndex: newState.activeTabIndex,
    startDate: newState.startDate.toString(),
    endDate: newState.endDate.toString(),
    compareWithPrevious: newState.compareWithPrevious,
    topListViewAll: newState.topListViewAll,
  }
  localStorage.setItem(STORAGE_KEY, JSON.stringify(storedState))
}
