import { Icon, ToolTip } from "@hubrise/react-components"
import type { IconCode } from "@hubrise/react-components"
import { DateTime } from "luxon"
import * as React from "react"
import { useTranslation } from "react-i18next"

import { OrderData } from "react-app/utils/queries/OrderData"

import { calculateOrderMetrics, calculateProgression, MetricType } from "../OrderMetrics"

import { Progression, ToolTipContent, ToolTipPreviousValue } from "./Styles"

interface CompareProps {
  previousData: OrderData
  metricType: MetricType
  previousStart: DateTime
  previousEnd: DateTime
  currentValue: number
}

const Compare = ({ previousData, metricType, previousStart, previousEnd, currentValue }: CompareProps): JSX.Element => {
  const { t } = useTranslation()

  const previousMetrics = calculateOrderMetrics(previousData)

  const previousValue = previousMetrics[metricType.key]
  const progression = calculateProgression(currentValue, previousValue)

  return (
    <ToolTip
      label={
        <ToolTipContent>
          <div>
            {t("dashboard.sales_summary.tooltip.previous_value")}
            <ToolTipPreviousValue>{metricType.format(previousValue)}</ToolTipPreviousValue>
          </div>
          <div>
            {t("dashboard.sales_summary.tooltip.period", {
              start: previousStart.toFormat("dd/MM/yyyy HH:mm"),
              end: previousEnd.toFormat("dd/MM/yyyy HH:mm"),
            })}
          </div>
        </ToolTipContent>
      }
    >
      <Progression $isPositive={progression.isPositive} data-testid={`progression-${metricType.key}`}>
        {progression.isPositive ? (
          <Icon code={"arrow_upward" as IconCode} />
        ) : (
          <Icon code={"arrow_downward" as IconCode} />
        )}
        {Math.abs(progression.value).toFixed(1)}%
      </Progression>
    </ToolTip>
  )
}

export default Compare
