import { useTranslation } from "react-i18next"

import { PermissionGroup, PermissionTemplate } from "./types"

export const useUiConstants = (): {
  permissionGroups: Array<PermissionGroup>
  permissionTemplates: Array<PermissionTemplate>
} => {
  const { t } = useTranslation()

  const permissionGroups: Array<PermissionGroup> = [
    {
      id: "back_office",
      name: t("roles.groups.back_office.name"),
      description: t("roles.groups.back_office.description"),
      permissions: [
        {
          attribute: "view_back_office",
          name: t("roles.permissions.view_back_office.name"),
          description: t("roles.permissions.view_back_office.description"),
          enabled: (_role) => "enabled",
        },
        {
          attribute: "manage_connections_and_resources",
          name: t("roles.permissions.manage_connections_and_resources.name"),
          description: t("roles.permissions.manage_connections_and_resources.description"),
          enabled: (_role) => "enabled",
        },
        {
          attribute: "manage_entity",
          name: t("roles.permissions.manage_entity.name"),
          description: t("roles.permissions.manage_entity.description"),
          enabled: (_role) => "enabled",
        },
        {
          attribute: "manage_billing",
          name: t("roles.permissions.manage_billing.name"),
          description: t("roles.permissions.manage_billing.description"),
          enabled: (_role) => "enabled",
        },
      ],
    },
    {
      id: "apps",
      name: t("roles.groups.apps.name"),
      description: t("roles.groups.apps.description"),
      permissions: [
        {
          attribute: "use_orderline",
          name: t("roles.permissions.use_orderline.name"),
          description: t("roles.permissions.use_orderline.description"),
          enabled: (role) => (role.permissions.view_back_office ? "disabledForcedTrue" : "enabled"),
        },
        {
          attribute: "use_catalog_manager",
          name: t("roles.permissions.use_catalog_manager.name"),
          description: t("roles.permissions.use_catalog_manager.description"),
          enabled: (role) =>
            role.entity?.location
              ? "disabledForcedFalse"
              : role.permissions.view_back_office
                ? "disabledForcedTrue"
                : "enabled",
        },
      ],
    },
  ]

  const permissionTemplates: Array<PermissionTemplate> = [
    {
      name: t("roles.templates.full_administrator.name"),
      description: t("roles.templates.full_administrator.description"),
      permissions: [
        "view_back_office",
        "manage_connections_and_resources",
        "manage_entity",
        "manage_billing",
        "use_orderline",
        "use_catalog_manager",
      ],
      enabled: (_role) => true,
    },
    {
      name: t("roles.templates.technical_partner.name"),
      description: t("roles.templates.technical_partner.description"),
      permissions: [
        "view_back_office",
        "manage_connections_and_resources",
        "manage_entity",
        "use_orderline",
        "use_catalog_manager",
      ],
      enabled: (_role) => true,
    },
    {
      name: t("roles.templates.backoffice_viewer.name"),
      description: t("roles.templates.backoffice_viewer.description"),
      permissions: ["view_back_office", "use_orderline", "use_catalog_manager"],
      enabled: (_role) => true,
    },
    {
      name: t("roles.templates.accountant.name"),
      description: t("roles.templates.accountant.description"),
      permissions: ["view_back_office", "manage_billing", "use_orderline", "use_catalog_manager"],
      enabled: (_role) => true,
    },
    {
      name: t("roles.templates.orderline_operator.name"),
      description: t("roles.templates.orderline_operator.description"),
      permissions: ["use_orderline"],
      enabled: (_role) => true,
    },
    {
      name: t("roles.templates.catalog_manager_operator.name"),
      description: t("roles.templates.catalog_manager_operator.description"),
      permissions: ["use_catalog_manager"],
      enabled: (role) => !role.entity?.location,
    },
  ]

  return { permissionGroups, permissionTemplates }
}
