let url: string

const init = function (): void {
  url = $("[data-invoice-clipboard]").attr("data-invoice-clipboard") ?? ""
  $("[data-invoice-clipboard]").click(copyToClipboard)
}

const copyToClipboard = function (e: JQuery.ClickEvent): void {
  e.preventDefault()
  const eids: string[] = []

  $("[data-invoice-id]:checked").each(function (this: HTMLElement) {
    const invoiceId = $(this).attr("data-invoice-id") ?? ""
    if (invoiceId) {
      eids.push(invoiceId)
    }
  })

  if (eids.length === 0) return

  $.ajax({
    url: url + "&billing_invoice_ids=" + eids.join(","),
  }).done(function (data: string) {
    $("#clipboard_reveal").foundation("open")
    $("#clipboard_textarea").val(data).select()
  })
}

const InvoiceClipboard: typeof window.InvoiceClipboard = {
  init,
}

export default InvoiceClipboard
