import * as React from "react"

import { PermissionAttribute, Permissions } from "react-app/Permissions/data/Permissions"
import { Role, togglePermission } from "react-app/Permissions/data/Role"

import { SinglePermission } from "../types"

import {
  CheckboxDescription,
  CheckboxInput,
  CheckboxLabel,
  CheckboxName,
  CheckboxText,
  StyledPermission,
} from "./Styles"

interface PermissionProps {
  permission: SinglePermission
  role: Role
  permissions: Permissions
  setPermissions: React.Dispatch<React.SetStateAction<Permissions>>
}

const Permission = ({ permission, role, permissions, setPermissions }: PermissionProps): JSX.Element | null => {
  const toggleOnePermission = (permission: PermissionAttribute) =>
    setPermissions((permissions) => togglePermission({ ...role, permissions }, permission))

  // If the permission is disabled for the role, don't show it, unless it's forced to be true
  let isDisabled = false
  const enabled = permission.enabled(role)
  if (enabled === "disabledForcedFalse") {
    return null
  } else if (enabled === "disabledForcedTrue") {
    isDisabled = true
  }

  const inline = { $inline: false }
  return (
    <StyledPermission key={permission.attribute}>
      <CheckboxLabel {...inline}>
        <CheckboxInput
          type="checkbox"
          checked={permissions[permission.attribute]}
          onChange={() => toggleOnePermission(permission.attribute)}
          disabled={isDisabled}
        />
        <CheckboxText {...inline}>
          <CheckboxName>{permission.name}</CheckboxName>
          {permission.description && <CheckboxDescription {...inline}>{permission.description}</CheckboxDescription>}
        </CheckboxText>
      </CheckboxLabel>
    </StyledPermission>
  )
}

export default Permission
