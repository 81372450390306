{
  "dashboard": {
    "header": {
      "title": "Tableau de bord"
    },
    "sales_graph": {
      "tabs": {
        "revenue": "Revenus",
        "orders": "Commandes",
        "average_order": "Commande moyenne"
      }
    },
    "peak_hours": {
      "title": "Heures de pointe"
    },
    "top_list": {
      "title": "Produits les plus vendus",
      "view_all": "Voir tout",
      "view_less": "Voir moins"
    },
    "revenue_pie": {
      "channel": {
        "title": "Revenu par canal"
      },
      "connection_name": {
        "labels": {
          "empty": "Pas de nom de connexion"
        },
        "title": "Revenu par nom de connexion",
        "tooltip": "Les commandes passées avant le 1er août 2024 sont classées dans \"Pas de nom de connexion\"."
      },
      "location": {
        "title": "Revenu par point de vente"
      }
    },
    "sales_summary": {
      "average_order": "Commande moyenne",
      "comparison_unavailable": {
        "label": "Comparer avec la période précédente",
        "tooltip": {
          "location": "Comparaison indisponible - le point de vente a été créé pendant la période précédente",
          "account": "Comparaison indisponible - le compte a été créé pendant la période précédente"
        }
      },
      "title": "Récapitulatif",
      "tooltip": {
        "period": "Calculée du {{start}} au {{end}}",
        "previous_value": "Valeur précédente :"
      },
      "total_revenue": "Revenu total",
      "total_orders": "Total des commandes"
    }
  },
  "dateRanges": {
    "custom": {
      "current": "Période personnalisée",
      "previous": "Période précédente",
      "compare_with": "Comparer avec la période précédente"
    },
    "lastFourWeeks": {
      "current": "4 dernières semaines",
      "previous": "4 semaines précédentes",
      "compare_with": "Comparer avec les 4 semaines précédentes"
    },
    "lastSevenDays": {
      "current": "7 derniers jours",
      "previous": "7 jours précédents",
      "compare_with": "Comparer avec les 7 jours précédents"
    },
    "today": {
      "current": "Aujourd'hui",
      "previous": "Hier",
      "compare_with": "Comparer avec hier"
    },
    "yesterday": {
      "current": "Hier",
      "previous": "Avant-hier",
      "compare_with": "Comparer avec avant-hier"
    },
    "currentWeek": {
      "current": "Semaine en cours",
      "previous": "Semaine précédente",
      "compare_with": "Comparer avec la semaine précédente"
    },
    "currentMonth": {
      "current": "Mois en cours",
      "previous": "Mois précédent",
      "compare_with": "Comparer avec le mois précédent"
    },
    "currentQuarter": {
      "current": "Trimestre en cours",
      "previous": "Trimestre précédent",
      "compare_with": "Comparer avec le trimestre précédent"
    },
    "currentYear": {
      "current": "Année en cours",
      "previous": "Année précédente",
      "compare_with": "Comparer avec l'année précédente"
    },
    "fromBeginning": {
      "current": "Depuis le début"
    }
  },
  "general": {
    "cancel": "Annuler",
    "close": "Fermer",
    "colon": " :",
    "date": {
      "hour_short": "h",
      "week_short": "Sem."
    },
    "edit": "Modifier",
    "format": {
      "date_time_long": "d MMMM yyyy HH'h'mm"
    },
    "remove": "Supprimer",
    "error": "Une erreur s'est produite : {{message}}",
    "error_without_message": "Une erreur s'est produite",
    "loading": "Chargement...",
    "no_data": "Pas de données",
    "orders_zero": "0 commande",
    "orders_one": "1 commande",
    "orders_other": "{{count}} commandes"
  },
  "profile": {
    "create_account": "Créer un compte"
  },
  "roles": {
    "invite": {
      "title": "Envoyer une invitation",
      "message": "Nous allons envoyer une invitation à <Email>{{email}}</Email>. Vous pourrez définir les permissions sur l'écran suivant.",
      "invite_user": "Envoyer l'invitation",
      "invitation_sent": "Nous venons d'envoyer une invitation à {{email}}",
      "send_copy": "Recevoir une copie de l'e-mail d'invitation"
    },
    "invitation_details": {
      "title": "Invitation en attente",
      "message_1": "Nous avons envoyé une invitation à <Email>{{email}}</Email> le {{date}}, de la part de {{inviter}}.",
      "message_2": "Si l'utilisateur n'a pas reçu l'e-mail, vous pouvez renvoyer l'invitation.",
      "resend_invitation": "Renvoyer l'invitation",
      "invitation_resent": "Nous venons de renvoyer l'invitation à {{email}}"
    },
    "list": {
      "entity": "Compte/Point de vente",
      "locked": "Les permissions globales sont uniquement modifiables dans la base de données.",
      "title": "Permissions",
      "user": "Utilisateur",
      "email": "E-mail",
      "permissions": "Permissions",
      "invitation_pending": "Invitation en attente"
    },
    "add_role": {
      "add_user": "Ajouter l'utilisateur",
      "edit_permissions": "Modifier les permissions",
      "invite_user": "Inviter l'utilisateur",
      "status": {
        "searching": "Recherche...",
        "user_not_found": "Aucun utilisateur trouvé avec cet e-mail",
        "entity_not_found": "Aucun compte ou point de vente trouvé",
        "not_an_email": "Entrez une adresse e-mail valide"
      },
      "email_placeholder": "E-mail",
      "query_placeholder": "Nom ou id du compte/point de vente"
    },
    "errors": {
      "cannot_remove_entity_permission": {
        "title": "Action impossible",
        "message": "Au moins un utilisateur doit conserver la permission Entité pour gérer les paramètres et les utilisateurs du compte."
      }
    },
    "remove": {
      "title": "Supprimer la permission",
      "description": "Êtes-vous sûr de vouloir supprimer la permission pour {{name}} ?"
    },
    "edit_permissions": {
      "title": {
        "user_role": "Permissions pour {{email}}",
        "entity_role": "Permissions pour {{name}}"
      },
      "templates": "Choisir un rôle prédéfini",
      "set_permissions": "Définir les permissions",
      "cancel": "Annuler"
    },
    "permissions": {
      "global_admin": {
        "badge": "Admin global"
      },
      "global_sales": {
        "badge": "Support global"
      },
      "view_back_office": {
        "badge": "Voir back-office",
        "name": "Voir le back-office",
        "description": "Voir le tableau de bord, les connexions et les données, utiliser toute application connectée."
      },
      "manage_connections_and_resources": {
        "badge": "Connexions et ressources",
        "name": "Gérer les connexions et ressources",
        "description": "Créer et gérer les connexions, catalogues et listes clients, consulter les logs."
      },
      "manage_entity": {
        "badge": "Entité",
        "name": "Gérer l'entité",
        "description": "Gérer les utilisateurs et les permissions, gérer et créer les points de vente d'un compte."
      },
      "manage_billing": {
        "badge": "Facturation",
        "name": "Gérer la facturation",
        "description": "Accéder aux factures et moyens de paiement, recevoir les e-mails de facturation."
      },
      "use_orderline": {
        "badge": "OrderLine",
        "name": "Utiliser OrderLine",
        "description": "Application HubRise pour recevoir et gérer les commandes, changer les horaires d'ouverture et mettre à jour l'inventaire."
      },
      "use_catalog_manager": {
        "badge": "Catalog Manager",
        "name": "Utiliser Catalog Manager",
        "description": "Application HubRise pour gérer les catalogues, voir et modifier les produits et les prix. Accès au niveau compte uniquement."
      }
    },
    "self_locking_warning": {
      "title": "Attention : Vous êtes sur le point de restreindre votre propre accès",
      "message_1": "Vous êtes sur le point de supprimer votre permission \"Gérer l'entité\". Cela vous retirera immédiatement l'accès à la gestion des permissions et vous ne pourrez pas annuler cette action.",
      "remove_message_1": "Vous êtes sur le point de supprimer toutes vos permissions sur l'entité. Vous ne pourrez pas restaurer cet accès vous-même.",
      "message_2": "Un autre utilisateur avec les permissions appropriées devra restaurer votre accès si nécessaire.",
      "confirm": "Oui, retirer mon accès"
    },
    "groups": {
      "back_office": {
        "name": "Accès back-office",
        "description": "Pour les administrateurs et les managers"
      },
      "apps": {
        "name": "Applications HubRise",
        "description": "Inclus avec toute permission d'accès back-office"
      }
    },
    "templates": {
      "custom": {
        "name": "Rôle personnalisé"
      },
      "full_administrator": {
        "name": "Administrateur complet",
        "description": "Accès complet au back-office et à toutes les applications"
      },
      "technical_partner": {
        "name": "Partenaire technique",
        "description": "Accès complet au back-office et aux applications, sauf la facturation"
      },
      "backoffice_viewer": {
        "name": "Observateur back-office",
        "description": "Accès en lecture seule au back-office et accès aux applications"
      },
      "accountant": {
        "name": "Responsable comptabilité",
        "description": "Accès limité au back-office, consultation des factures et gestion des moyens de paiement"
      },
      "orderline_operator": {
        "name": "Opérateur OrderLine",
        "description": "Accès uniquement à OrderLine"
      },
      "catalog_manager_operator": {
        "name": "Gestionnaire catalogue",
        "description": "Accès uniquement au Catalog Manager"
      }
    }
  }
}