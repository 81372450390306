import { Icon, iconSizes } from "@hubrise/react-components"
import type { IconCode } from "@hubrise/react-components"
import * as React from "react"
import { useTranslation } from "react-i18next"

import PlainDate from "react-app/utils/PlainDate"
import { getDashboardLocalStorage, setDashboardLocalStorage } from "react-app/utils/localStorage"
import { getCustomRange, getPredefinedRanges, nowInLocalTime } from "react-app/utils/utils"

import PeakHours from "./PeakHours"
import RevenuePie from "./RevenuePie"
import SalesGraph from "./SalesGraph"
import SalesSummary from "./SalesSummary"
import { CardBody, CardHeader, CardHeaderIcon, DateSelector, OverviewCard, OverviewGrid, StyledToolTip } from "./Styles"
import TopProducts from "./TopProducts"
import DateRangePicker from "./shared/DateRangePicker"
import SumoSelect, { SumoSelectProps } from "./shared/SumoSelect"
import Tabs from "./shared/Tabs"

const Dashboard = (): JSX.Element => {
  const { t } = useTranslation()

  const [remountDateSelectorKey, remountDateSelector] = React.useReducer((key: number) => key + 1, 0)

  const [dashboardState, setDashboardState] = React.useState(() => getDashboardLocalStorage())
  const { selectedRangeIndex, activeTabIndex, startDate, endDate, compareWithPrevious, topListViewAll } = dashboardState
  const updateDashboardState = (newState: Partial<typeof dashboardState>) => {
    setDashboardState((prevState) => {
      const updatedState = { ...prevState, ...newState }
      setDashboardLocalStorage(updatedState)
      return updatedState
    })
    remountDateSelector()
  }

  const predefinedRanges = getPredefinedRanges(nowInLocalTime(), t)
  const selectedRange =
    selectedRangeIndex === "custom"
      ? getCustomRange(startDate, endDate, t)
      : (predefinedRanges[selectedRangeIndex] ?? predefinedRanges[0])

  const selectorOptions: SumoSelectProps["options"] = [
    ...predefinedRanges.map(({ current: { label } }, i) => ({
      label,
      value: String(i),
    })),
    { label: t("dateRanges.custom.current"), value: "custom" },
  ]

  return (
    <>
      <header className="section__header header">
        <h3 className="header__title">{t("dashboard.header.title")}</h3>
        <div className="header__controls header__controls_large">
          <DateSelector key={remountDateSelectorKey}>
            <Icon code={"calendar_month" as IconCode} size={iconSizes._20} />

            <DateRangePicker
              key="date-range-picker"
              initialStartDate={selectedRange.current.start}
              initialEndDate={selectedRange.current.end}
              onTimeChange={(startDate, endDate) =>
                updateDashboardState({
                  selectedRangeIndex: "custom",
                  startDate,
                  endDate,
                })
              }
            />

            <SumoSelect
              key="date-range-selector"
              options={selectorOptions}
              value={String(selectedRangeIndex)}
              onChange={(value) => {
                if (value === "custom") {
                  updateDashboardState({
                    selectedRangeIndex: "custom",
                  })
                } else {
                  updateDashboardState({
                    selectedRangeIndex: Number(value),
                    startDate: predefinedRanges[Number(value)].current.start,
                    endDate: predefinedRanges[Number(value)].current.end,
                  })
                }
              }}
            />
          </DateSelector>
        </div>
      </header>

      <div className="section__body">
        <OverviewGrid>
          <OverviewCard $span={2}>
            <Tabs
              activeIndex={activeTabIndex}
              setActiveIndex={(index) => updateDashboardState({ activeTabIndex: index })}
            >
              {[
                {
                  label: t("dashboard.sales_graph.tabs.revenue"),
                  content: (
                    <SalesGraph
                      type="revenue"
                      dateRange={selectedRange.current}
                      previousDateRange={compareWithPrevious ? selectedRange.previous : undefined}
                    />
                  ),
                },
                {
                  label: t("dashboard.sales_graph.tabs.orders"),
                  content: (
                    <SalesGraph
                      type="orders"
                      dateRange={selectedRange.current}
                      previousDateRange={compareWithPrevious ? selectedRange.previous : undefined}
                    />
                  ),
                },
                {
                  label: t("dashboard.sales_graph.tabs.average_order"),
                  content: (
                    <SalesGraph
                      type="average_order"
                      dateRange={selectedRange.current}
                      previousDateRange={compareWithPrevious ? selectedRange.previous : undefined}
                    />
                  ),
                },
              ]}
            </Tabs>
          </OverviewCard>

          <OverviewCard $span={1}>
            <CardHeader>{t("dashboard.sales_summary.title")}</CardHeader>
            <CardBody>
              <SalesSummary
                dateRange={selectedRange.current}
                previousDateRange={selectedRange.previous}
                compareWithLabel={selectedRange.compareWithLabel}
                compare={compareWithPrevious}
                toggleCompare={() => updateDashboardState({ compareWithPrevious: !compareWithPrevious })}
              />
            </CardBody>
          </OverviewCard>

          <OverviewCard $span={1}>
            <CardHeader>{t("dashboard.revenue_pie.channel.title")}</CardHeader>
            <CardBody>
              <RevenuePie dateRange={selectedRange.current} groupBy="channel" />
            </CardBody>
          </OverviewCard>

          <OverviewCard $span={1}>
            <CardHeader>
              {t("dashboard.revenue_pie.connection_name.title")}
              {selectedRange.current.start.daysBetween(PlainDate.fromString("2024-08-01")) > 0 && (
                <CardHeaderIcon>
                  <StyledToolTip label={t("dashboard.revenue_pie.connection_name.tooltip")}>
                    <Icon code="info" size={iconSizes._20} />
                  </StyledToolTip>
                </CardHeaderIcon>
              )}
            </CardHeader>
            <CardBody>
              <RevenuePie dateRange={selectedRange.current} groupBy="connection_name" />
            </CardBody>
          </OverviewCard>

          <OverviewCard $span={1}>
            <CardHeader>{t("dashboard.revenue_pie.location.title")}</CardHeader>
            <CardBody>
              <RevenuePie dateRange={selectedRange.current} groupBy="location" />
            </CardBody>
          </OverviewCard>

          <OverviewCard $span={3}>
            <CardHeader>{t("dashboard.peak_hours.title")}</CardHeader>
            <CardBody>
              <PeakHours dateRange={selectedRange.current} />
            </CardBody>
          </OverviewCard>

          <OverviewCard $span={3}>
            <CardHeader>{t("dashboard.top_list.title")}</CardHeader>
            <CardBody>
              <TopProducts
                dateRange={selectedRange.current}
                viewAll={topListViewAll}
                toggleViewAll={() => updateDashboardState({ topListViewAll: !topListViewAll })}
              />
            </CardBody>
          </OverviewCard>
        </OverviewGrid>
      </div>
    </>
  )
}

export default Dashboard
