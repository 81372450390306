{
  "dashboard": {
    "header": {
      "title": "Dashboard"
    },
    "sales_graph": {
      "tabs": {
        "revenue": "Revenue",
        "orders": "Orders",
        "average_order": "Average order"
      }
    },
    "peak_hours": {
      "title": "Peak Hours"
    },
    "top_list": {
      "title": "Most-Sold Products",
      "view_all": "View all",
      "view_less": "View less"
    },
    "revenue_pie": {
      "channel": {
        "title": "Revenue By Channel"
      },
      "connection_name": {
        "labels": {
          "empty": "No connection name"
        },
        "title": "Revenue By Connection Name",
        "tooltip": "Orders before August 1, 2024 are categorised in \"No connection name\"."
      },
      "location": {
        "title": "Revenue By Location"
      }
    },
    "sales_summary": {
      "average_order": "Average Order",
      "comparison_unavailable": {
        "label": "Compare with previous period",
        "tooltip": {
          "location": "Comparison unavailable - location was created during previous period",
          "account": "Comparison unavailable - account was created during previous period"
        }
      },
      "title": "Summary",
      "tooltip": {
        "period": "Calculated from {{start}} to {{end}}",
        "previous_value": "Previous value:"
      },
      "total_revenue": "Total Revenue",
      "total_orders": "Total Orders"
    }
  },
  "dateRanges": {
    "custom": {
      "current": "Custom period",
      "previous": "Previous period",
      "compare_with": "Compare with previous period"
    },
    "lastFourWeeks": {
      "current": "Last 4 weeks",
      "previous": "4 weeks before",
      "compare_with": "Compare with the previous 4 weeks"
    },
    "lastSevenDays": {
      "current": "Last 7 days",
      "previous": "7 days before",
      "compare_with": "Compare with the previous 7 days"
    },
    "today": {
      "current": "Today",
      "previous": "Yesterday",
      "compare_with": "Compare with yesterday"
    },
    "yesterday": {
      "current": "Yesterday",
      "previous": "Day before yesterday",
      "compare_with": "Compare with the day before yesterday"
    },
    "currentWeek": {
      "current": "Current week",
      "previous": "Last week",
      "compare_with": "Compare with last week"
    },
    "currentMonth": {
      "current": "Current month",
      "previous": "Last month",
      "compare_with": "Compare with last month"
    },
    "currentQuarter": {
      "current": "Current quarter",
      "previous": "Last quarter",
      "compare_with": "Compare with last quarter"
    },
    "currentYear": {
      "current": "Current year",
      "previous": "Last year",
      "compare_with": "Compare with last year"
    },
    "fromBeginning": {
      "current": "From the beginning"
    }
  },
  "general": {
    "cancel": "Cancel",
    "close": "Close",
    "colon": ":",
    "date": {
      "hour_short": "h",
      "week_short": "Week"
    },
    "edit": "Edit",
    "format": {
      "date_time_long": "d MMMM yyyy h:mm a"
    },
    "remove": "Remove",
    "error": "An error occurred: {{message}}",
    "error_without_message": "An error occurred",
    "loading": "Loading...",
    "no_data": "No data",
    "orders_one": "1 order",
    "orders_other": "{{count}} orders"
  },
  "profile": {
    "create_account": "Create account"
  },
  "roles": {
    "invite": {
      "title": "Send an invitation",
      "message": "We are going to send an invitation email to <Email>{{email}}</Email>. You can set permissions on the next screen.",
      "invite_user": "Send invitation",
      "invitation_sent": "We have just sent an invitation to {{email}}",
      "send_copy": "Receive a copy of the invitation email"
    },
    "invitation_details": {
      "title": "Invitation pending",
      "message_1": "We have sent an invitation to <Email>{{email}}</Email> on {{date}}, on behalf of {{inviter}}.",
      "message_2": "If the user hasn't received the email, you can resend the invitation.",
      "resend_invitation": "Resend invitation",
      "invitation_resent": "We have just resent the invitation to {{email}}"
    },
    "list": {
      "entity": "Account/Location",
      "locked": "Global permissions can only be modified in the database.",
      "title": "Permissions",
      "user": "User",
      "email": "Email",
      "permissions": "Permissions",
      "invitation_pending": "Invitation pending"
    },
    "add_role": {
      "add_user": "Add user",
      "edit_permissions": "Edit permissions",
      "invite_user": "Invite user",
      "status": {
        "searching": "Searching...",
        "user_not_found": "No user found with this email",
        "entity_not_found": "No account or location found",
        "not_an_email": "Enter a valid email address"
      },
      "email_placeholder": "Email",
      "query_placeholder": "Account/location name or ID"
    },
    "errors": {
      "cannot_remove_entity_permission": {
        "title": "Action not allowed",
        "message": "At least one user must retain Entity permission to manage account settings and users."
      }
    },
    "remove": {
      "title": "Remove permission",
      "description": "Are you sure you want to remove the permission for {{name}}?"
    },
    "edit_permissions": {
      "title": {
        "user_role": "Permissions for {{email}}",
        "entity_role": "Permissions for {{name}}"
      },
      "templates": "Choose a predefined role",
      "set_permissions": "Set permissions",
      "cancel": "Cancel"
    },
    "permissions": {
      "global_admin": {
        "badge": "Global admin"
      },
      "global_sales": {
        "badge": "Global support"
      },
      "view_back_office": {
        "badge": "View Back Office",
        "name": "View Back Office",
        "description": "View dashboard, connections and data, use any connected app."
      },
      "manage_connections_and_resources": {
        "badge": "Connections and Resources",
        "name": "Manage Connections and Resources",
        "description": "Create and manage connections, catalogs and customer lists, view logs."
      },
      "manage_entity": {
        "badge": "Entity",
        "name": "Manage Entity",
        "description": "Manage users and permissions, manage and create locations of an account."
      },
      "manage_billing": {
        "badge": "Billing",
        "name": "Manage Billing",
        "description": "Access invoices and payment methods, receive billing emails."
      },
      "use_orderline": {
        "badge": "OrderLine",
        "name": "Use OrderLine",
        "description": "HubRise app to receive and manage orders, change opening hours and update inventory."
      },
      "use_catalog_manager": {
        "badge": "Catalog Manager",
        "name": "Use Catalog Manager",
        "description": "HubRise app to manage catalogs, view and edit products and pricing. Account-level access only."
      }
    },
    "self_locking_warning": {
      "title": "Warning: You are about to restrict your own access",
      "message_1": "You are about to remove your \"Manage Entity\" permission. This will immediately remove your access to permission management and cannot be undone by you.",
      "remove_message_1": "You are about to remove all your permissions on the entity. You won't be able to restore this access yourself.",
      "message_2": "Another user with appropriate permissions will need to restore your access if needed.",
      "confirm": "Yes, remove my access"
    },
    "groups": {
      "back_office": {
        "name": "Back Office Access",
        "description": "For administrators and managers"
      },
      "apps": {
        "name": "HubRise Applications",
        "description": "Included with any Back Office Access permission"
      }
    },
    "templates": {
      "custom": {
        "name": "Custom Role"
      },
      "full_administrator": {
        "name": "Full Administrator",
        "description": "Full back office access and all applications"
      },
      "technical_partner": {
        "name": "Technical Partner",
        "description": "Full back office and application access, except billing"
      },
      "backoffice_viewer": {
        "name": "Back Office Viewer",
        "description": "Read-only access to the back office and access to applications"
      },
      "accountant": {
        "name": "Billing Manager",
        "description": "Limited back office access, view invoices and manage payment methods"
      },
      "orderline_operator": {
        "name": "OrderLine Operator",
        "description": "Access to OrderLine only"
      },
      "catalog_manager_operator": {
        "name": "Catalog Manager",
        "description": "Access to Catalog Manager only"
      }
    }
  }
}