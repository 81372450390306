import Vue from 'vue/dist/vue.min.js'

document.addEventListener('DOMContentLoaded', () => {
  const element = document.getElementById('quote-lines-vue-app')

  if (!window.quoteLines) { window.quoteLines = [] }

  if (element != null) {
    window.quoteFormLines = new Vue({
      el: element,
      data: function () {
        return {
          quoteLines: window.quoteLines,
          editingIndex: null,
          new_quote_line: {
            description: 'New line',
            amount: 0
          }
        }
      },
      methods: {
        addQuoteLine: function () {
          this.quoteLines.push(
            {
              description: this.new_quote_line.description,
              amount: this.new_quote_line.amount
            }
          )
        },
        removeQuoteLine: function (quoteLine, index) {
          if (quoteLine.id) {
            Vue.set(quoteLine, 'destroyed', true)
          } else {
            this.quoteLines.splice(index, 1)
          }

          this.stopEditing()
        },
        startEditing: function (index, ref) {
          this.editingIndex = index
          Vue.nextTick(() => {
            this.$refs[ref][index].focus()
          })
        },
        stopEditing: function () {
          this.editingIndex = null
        }
      },
      computed: {
        visibleQuoteLines: function () {
          return window.quoteLines.filter(function (quoteLine) {
            return !quoteLine.destroyed
          })
        }
      }
    })
  }
})
