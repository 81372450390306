import { defaultTheme, Toast } from "@hubrise/react-components"
import { QueryClientProvider } from "@tanstack/react-query"
import "react-app/utils/chartjs" // Initialize Chart.js
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import * as React from "react"
import { setDefaultLocale } from "react-datepicker"
import { I18nextProvider } from "react-i18next"
import { ThemeProvider } from "styled-components"

import Dashboard from "react-app/Dashboard"
import Permissions, { PermissionContext } from "react-app/Permissions"
import { bodyData } from "react-app/utils/config"
import { queryClient } from "react-app/utils/queries"

import i18n from "./utils/i18n"

export type AppProps =
  | {
      type: "dashboard"
    }
  | {
      type: "permissions"
      context: PermissionContext
    }

const App = (props: AppProps): JSX.Element => {
  const component = (() => {
    if (props.type === "dashboard") {
      return <Dashboard />
    } else if (props.type === "permissions") {
      return <Permissions context={props.context} />
    }
  })()

  return (
    <QueryClientProvider client={queryClient}>
      <I18nextProvider i18n={i18n}>
        <ThemeProvider theme={defaultTheme}>
          <SetLanguage>
            <Toast />

            {component}
          </SetLanguage>
        </ThemeProvider>
      </I18nextProvider>

      <ReactQueryDevtools />
    </QueryClientProvider>
  )
}

export const SetLanguage = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const [languageLoaded, setLanguageLoaded] = React.useState(false)

  const { locale } = bodyData()
  React.useEffect(() => {
    const changeLanguageAsync = async () => {
      await i18n.changeLanguage(locale)
      setDefaultLocale(locale)
      setLanguageLoaded(true)
    }

    void changeLanguageAsync()
  }, [locale])

  return languageLoaded ? <>{children}</> : <div>Loading...</div>
}

export default App
